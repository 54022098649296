function getMonthName(month) {
  const d = new Date();
  d.setMonth(month - 1);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}

function setTimeTwelve() {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export const getDate = (d) => {
  const date = new Date(d);
  const day = date.getDate(); // Date of the month: 2 in our example
  let month = date.getMonth(); // Month of the Year: 0-based index, so 1 in our example
  const year = date.getFullYear(); // Year: 2013
  //const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //const dayofweek = weekday[date.getDay()];
  month = getMonthName(month);
  return `${day} ${month} ${year}`;
};

export const getTime = (date) => {
  let dateChange = date ? new Date(date) : new Date();
  let time = dateChange.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return time;
};

export const dateTimeLine = (date, type) => {
  const dateVal = new Date(date);
  const previousDate = setTimeTwelve();
  const today = new Date();
  if (today.toDateString() === dateVal.toDateString()) {
    return "Today";
  } else if (previousDate.toDateString() === dateVal.toDateString()) {
    return "Yesterday";
  } else {
    if (type === "render") {
      return getDate(dateVal);
    } else {
      return dateVal.toDateString();
    }
  }
};

export const capitalizeFirstLetter = (string) => {
  if(!string){
    return ''
  }
  if (string.toLowerCase() === "pc") {
    return string.toUpperCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const changeCurrencyFormat = (number = 0) => {
  number = number.toString();
  number = number
    .split("")
    .reverse()
    .join("")
    .replace(/([0-9]{3})/g, "$1 ")
    .split("")
    .reverse()
    .join("");
  return number;
};

export const changeStatusValue = (string) => {
  if (!string) {
    return "";
  }
  if (string === "accept") {
    string = "Confirmed";
  } else if (string === "reject") {
    string = "Cancelled";
  }
  return string;
};

export const isValidUrl = (urlString) => {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(urlString)) {
    return true;
  } else {
    return false;
  }
};

export const getLinkRoute = (notificationData) => {
  if (notificationData?.typeRef === "Transaction") {
    const { transaction } = notificationData?.contentId || {};
    if (transaction === "redeem") {
      return "game-redeem";
    } else if (transaction === "recharge") {
      return "game-recharge";
    } else {
      return transaction;
    }
  }

  if (notificationData.typeRef === "GameRequest") {
    return "game-request";
  }

  if (notificationData.typeRef === "GameDistribution") {
    return "distributor-request";
  }
};

export const multiplyValue=(amount=0,multiplyBy=0)=>{
  const res = amount * multiplyBy;
  return res
}


