import { useState, useEffect, useCallback } from "react";
import AdminLayout from "../layout/layout";
import PageHeader from "../pageheader/pageheader";
import { Table } from "react-bootstrap";
import { getAllUsers, getTotalCoins } from "../../utils/Api";
import { changeCurrencyFormat, getDate } from "../../utils/index.js";
import rightArrow from "../../assets/images/table/right-arrow.svg";
import user from "../../assets/images/table/user2.png";
import downArrow from "../../assets/images/table/down-arrow.svg";
import coinicon from "../../assets/images/header/coin-icon.svg";
import "./clients.css";
import TableLoader from "../loader/tableloader";
import PagePaginationNew from "../pagination/PaginationNew";
import NoData from "../noData";
import { Link } from "react-router-dom";
import SearchBoxComponent from "../search/searchBoxComponent";

const Clients = () => {
  const [liveUsers, setLiveUsers] = useState([]);
  const initialObj = {
    first: 1,
    last: "",
    next: 2,
    prev: 1,
    activeindex: 1,
    skip: 0,
    limit: 10,
  };
  const [paginationStep, setPaginationStep] = useState(initialObj);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState({});

  //console.log('SET LIVE URSES....',liveUsers);
  const getUsers = async (paginationObj, step) => {
    setLoading(true);
    const result = await getAllUsers(setLiveUsers, paginationObj);
    setLoading(false);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      setPaginationStep(copyObj);
    }
  };

  useEffect(() => {
    getUsers(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getUsers(copyobj);
    setPaginationStep(copyobj);
  };
  const searchUser = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      await getAllUsers(setLiveUsers, searchString);
    },
    [paginationStep]
  );

  const getTotalCoinsValue = async () => {
    const res = await getTotalCoins("client", {});
    if (res.status === 200) {
      setTotalData(res.data.result[0] || {});
    }
  };
  useEffect(() => {
    getTotalCoinsValue();
  }, []);
  return (
    <AdminLayout>
      <div className="client-page">
        <PageHeader pageTitle="Players" totalData={totalData} />
        <div className="container">
          <div className="admin-content-wrapper">
            <SearchBoxComponent searchFunction={searchUser} />
            {!loading ? (
              <>
                {liveUsers?.results?.length > 0 ? (
                  <>
                    <div className="admin-table">
                      <div className="row">
                        <div className="col-md-12">
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Registration date</th>
                                <th>Games</th>
                                <th>Draft Balance</th>
                                <th>Total Balance</th>
                                <th>
                                  <img src={rightArrow} alt="arrow" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {liveUsers.results &&
                                liveUsers.results.length > 0 &&
                                liveUsers.results.map((element) => (
                                  <tr key={element?.id ?? element?._id}>
                                    <td>
                                      <div className="user-name">
                                        <img
                                          src={element?.avatar || user}
                                          alt=""
                                        />{" "}
                                        {element?.nickName}
                                      </div>
                                    </td>
                                    <td>{element?.email}</td>
                                    <td>{getDate(element?.createdAt)}</td>
                                    <td>{element?.userGames || 0}</td>
                                    <td>
                                      <div className="draft-balance">
                                        <img src={downArrow} alt="coin" /> $
                                        {changeCurrencyFormat(
                                          element?.draftBal || 0
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="total-balance">
                                        <img src={coinicon} alt="coin" /> $
                                        {element?.coin || 0}
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/players/playerProfile`}
                                        state={{ userDetails: element }}
                                      >
                                        <img src={rightArrow} alt="arrow" />
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          {liveUsers?.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationStep?.activeindex}
                              totalpages={liveUsers?.totalPages}
                              handlePagination={handlePagenation}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData heading="No Players Data" />
                )}
              </>
            ) : (
              <TableLoader />
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Clients;
