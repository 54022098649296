import axios from "axios";
import { server } from "../config/keys";

export const getToken = () => {
  const tokenData = localStorage.getItem("admintoken")
    ? JSON.parse(localStorage.getItem("admintoken"))
    : {};
  const { access: { token } = {} } = tokenData;
  return token;
};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const userInstance = () =>
  axios.create({
    baseURL: `${server}/v1/users`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const authInstance = () =>
  axios.create({
    baseURL: `${server}/v1/auth`,
    headers: { Authorization: getAuthorizationHeader() },
  });
export const adminInstance = () =>
  axios.create({
    baseURL: `${server}/v1/admin`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const transactionInstance = () =>
  axios.create({
    baseURL: `${server}/v1/transaction`,
    headers: { Authorization: getAuthorizationHeader() },
  });

export const gameInstance = () =>
  axios.create({
    baseURL: `${server}/v1/games`,
    headers: { Authorization: getAuthorizationHeader() },
  });
export const brandInstance = () =>
  axios.create({
    baseURL: `${server}/v1/brand`,
    headers: { Authorization: getAuthorizationHeader() },
  });
export const offerInstance = () =>
  axios.create({
    baseURL: `${server}/v1/offer`,
    headers: { Authorization: getAuthorizationHeader() },
  });
export const notificationInstance = () =>
  axios.create({
    baseURL: `${server}/v1/notification`,
    headers: { Authorization: getAuthorizationHeader() },
  });
