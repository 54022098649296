import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getDate } from "../../utils";
import linkicon from "../../assets/images/table/link-icon.svg";

const ViewDepositPopup = ({
  selectedSpecificDepositRequest,
  viewDepositRequest,
  closePopup,
  handleStatusUpdate,
}) => {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const toggleFullScreen = (url) => {
    if (fullScreenImage) {
      setFullScreenImage(null);
    } else {
      setFullScreenImage(url);
    }
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {isCopied ? "Copied" : "Copy"}
    </Tooltip>
  );

  return (
    <Modal
      size="lg"
      className="game-request-popup"
      show={viewDepositRequest}
      onHide={() => closePopup()}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="transaction-body">
          <div className="transaction-body-content px-4">
            <div className="cashtag-heading">
              <div className="deposit-heading">
                <h6>View Deposit Request</h6>
              </div>
            </div>
            <div className="view-deposit-grids row gap-2 flex-lg-nowrap">
              <div className="field-box col-lg-6 col-sm-12">
                <h6>
                  Name:{" "}
                  <span>
                    {selectedSpecificDepositRequest?.data?.userId?.nickName ||
                      ""}
                  </span>
                </h6>
              </div>
              <div className="field-box col-lg-6 col-sm-12 ">
                <h6>
                  Email:{" "}
                  <span>
                    {selectedSpecificDepositRequest?.data?.userId?.email || ""}
                  </span>
                </h6>
              </div>
            </div>
            <div className="view-deposit-grids row gap-2 flex-lg-nowrap">
              <div className="field-box col-lg-6 col-sm-12">
                <h6>
                  Deposit:{" "}
                  <span>
                    ${selectedSpecificDepositRequest?.data?.amountInUsd || ""}
                  </span>
                </h6>
              </div>
              <div className="field-box col-lg-6 col-sm-12">
                <h6>
                  Coin:{" "}
                  <span>
                    ${selectedSpecificDepositRequest?.data?.coin || ""}
                  </span>
                </h6>
              </div>
            </div>
            <div className="view-deposit-grids row gap-2 flex-lg-nowrap">
              <div className="field-box col-lg-6 col-sm-12">
                <h6>
                  Date:{" "}
                  <span>
                    {getDate(selectedSpecificDepositRequest?.data?.createdAt)}
                  </span>
                </h6>
              </div>
              <div className="field-box col-lg-6 col-sm-12">
                <h6>
                  Mode:{" "}
                  <span>
                    {(selectedSpecificDepositRequest?.data?.payment ===
                      "cashApp" &&
                      "Cash App") ||
                      ""}
                  </span>
                </h6>
              </div>
            </div>
            <div className="field-box col-lg-12 col-sm-12">
              <h6>
                Payment Recipient:{" "}
                <span
                  className="payment-recipient break-word"
                  value={selectedSpecificDepositRequest?.data?.paymentRecipient}
                >
                  {selectedSpecificDepositRequest?.data?.paymentRecipient}
                </span>
              </h6>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div
                  className=" link-icon w-auto"
                  onClick={() => {
                    setIsCopied(true);
                    navigator.clipboard.writeText(
                      selectedSpecificDepositRequest?.data?.paymentRecipient
                    );
                  }}
                >
                  <img src={linkicon} alt="link-icon" className="w-auto" />
                </div>
              </OverlayTrigger>
            </div>
            <div className="upload-image-box p-5">
              <div className="preview d-flex gap-1 justify-content-center align-items-center">
                {selectedSpecificDepositRequest?.data?.screenShots?.map(
                  (file, index) => (
                    <img
                      key={index}
                      src={file}
                      alt={`preview ${index}`}
                      onClick={() => toggleFullScreen(file)}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center my-4">
            <Button
              className="popup-confirm-btn btn btn-primary"
              onClick={() =>
                handleStatusUpdate(
                  selectedSpecificDepositRequest?.data?.id,
                  selectedSpecificDepositRequest?.index?.index
                )
              }
            >
              Confirm
            </Button>
          </div>
        </div>

        {fullScreenImage && (
          <div className="view-image" onClick={() => toggleFullScreen(null)}>
            <img src={fullScreenImage} alt="Full Screen" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewDepositPopup;
