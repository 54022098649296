import { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, Button, Modal, Form, Spinner } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import usericon1 from "../../assets/images/table/user3.png";
import gameicon from "../../assets/images/table/game.png";
import PagePaginationNew from "../pagination/PaginationNew";
import "./game-request.css";
import { fetchGameRequest, updateGameRequest } from "../../utils/Api";
import NoData from "../noData/index";
import { getDate } from "../../utils/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { gameCredentials } from "../../validationSchema/authSchema";
import TableLoader from "../loader/tableloader";

const GameRequest = () => {
  const [requestgameList, setRequestgameList] = useState([]);
  const [requestGameView, setRequestGameView] = useState("idle");
  const initialPaginationValue = {
    limit: 0,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    status: requestGameView,
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  const [openCredentialsPopup, setOpenCredenitalPopup] = useState(false);
  const [selectedGameReq, setSelectedGameRequest] = useState({
    gameId: "",
    gameIndex: -1,
  });
  const [loading, setLoading] = useState(false);
  const [mainLoading,setMainLoading] = useState(false)

  const openCredPopFn = (gameId, gameIndex) => {
    if (gameId && gameIndex !== -1) {
      setSelectedGameRequest({ gameId, gameIndex });
    }
    setOpenCredenitalPopup(!openCredentialsPopup);
  };

  useEffect(() => {
    const getGameRequestData = async () => {
      setMainLoading(true)
      const res = await fetchGameRequest(paginationValue);
      setMainLoading(false)
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.gameRequest;
        setRequestgameList(results || []);
        const pageData = {
          limit,
          page,
          totalPages,
          totalResults,
          status: requestGameView,
        };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getGameRequestData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValue?.page, requestGameView]);

  const handleGameRequestTab = (tab) => {
    setPaginationValue({ ...paginationValue, page: 1,status:tab });
    setRequestGameView(tab);
  };


  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  const updateReq = async (gameId, payload, index) => {
    setLoading(true);
    const res = await updateGameRequest(gameId, payload);
    setLoading(false);
    if (res.code === 201) {
      const copyRequestgameList = JSON.parse(JSON.stringify(requestgameList));
      copyRequestgameList.splice(index, 1);
      setRequestgameList(copyRequestgameList);
      return true;
    }
    return false;
  };
  const searchGamesReq = useCallback(
    async (searchValue) => {
      const copyPaginationValue = { ...paginationValue };
      copyPaginationValue.searchValue = searchValue;
      const res = await fetchGameRequest(copyPaginationValue);
      if (res.status === 200) {
        const { results } = res?.data?.gameRequest;
        setRequestgameList(results || []);
        // setPaginationValue(copyPaginationValue)
      }
    },
    [paginationValue]
  );

  return (
    <AdminLayout>
      <div className='game-request-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Game Request</h3>
            </div>
            <div className='admin-tab-section'>
              <Tabs
                defaultActiveKey='idle'
                id='uncontrolled-tab-example'
                onSelect={(tab) => handleGameRequestTab(tab)}>
                <Tab eventKey='idle' title='New'>
                  <div className='admin-game-rquest'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent searchFunction={searchGamesReq} />
                          {!mainLoading ? <>
                            <div className='admin-custom-table'>
                            {!!requestgameList.length ? (
                              <>
                                {requestgameList.map((el, i) => (
                                  <GameRequestRow
                                  key={el?.id?? el?._id}
                                    requestData={el}
                                    index={i}
                                    updateReq={updateReq}
                                    openCredPopFn={openCredPopFn}
                                  />
                                ))}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </div>
                          {paginationValue.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationValue?.page}
                              totalpages={paginationValue?.totalPages}
                              handlePagination={handlePagination}
                            />
                          )}
                          </> : <TableLoader/>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='accept' title='Confirmed'>
                  <div className='admin-game-confirm'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent />
                          {!mainLoading ? <>
                            <div className='admin-custom-table'>
                            {!!requestgameList.length ? (
                              <>
                                {requestgameList.map((el, i) => (
                                  <GameConfirmRow
                                  key={el?.id?? el?._id}
                                    requestData={el}
                                    index={i}
                                    updateReq={updateReq}
                                    openCredPopFn={openCredPopFn}
                                  />
                                ))}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </div>
                          {paginationValue.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationValue?.page}
                              totalpages={paginationValue?.totalPages}
                              handlePagination={handlePagination}
                            />
                          )}
                          </> : <TableLoader/>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='reject' title='Canceled'>
                  <div className='admin-game-cancle'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent />
                          {!mainLoading ? <>
                            <div className='admin-custom-table'>
                            {!!requestgameList.length ? (
                              <>
                                {requestgameList.map((el, i) => (
                                  <GameCancelRow
                                  key={el?.id?? el?._id}
                                    requestData={el}
                                    index={i}
                                    updateReq={updateReq}
                                    openCredPopFn={openCredPopFn}
                                  />
                                ))}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </div>
                          {paginationValue.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationValue?.page}
                              totalpages={paginationValue?.totalPages}
                              handlePagination={handlePagination}
                            />
                          )}
                          </> : <TableLoader/>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {openCredentialsPopup && (
          <GameCredentialsPopup
            openCredentialsPopup={openCredentialsPopup}
            openCredPopFn={openCredPopFn}
            updateReq={updateReq}
            selectedGameReq={selectedGameReq}
            loading={loading}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default GameRequest;

const GameRequestRow = ({ requestData, updateReq, index, openCredPopFn }) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={requestData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{requestData?.gameId?.name || ""}</h6>
          <p>{requestData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={requestData?.userId?.avatar||usericon1} alt='game' />
        <div className='user-name-box'>
          <h6>{requestData?.userId?.name || ""}</h6>
          <p>{requestData?.userId?.email || ""}</p>
        </div>
      </div>
      <div className='action-btn'>
        <Button
          className='cancle-btn'
          onClick={() =>
            updateReq(requestData?.id, { status: "reject" }, index)
          }>
          Cancel
        </Button>
        <Button
          className='confirm-btn'
          onClick={() => openCredPopFn(requestData?.id, index)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

const GameConfirmRow = ({ requestData, updateReq, index }) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={requestData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{requestData?.gameId?.name || ""}</h6>
          <p>{requestData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={requestData?.userId?.avatar||usericon1} alt='game' />
        <div className='user-name-box'>
          <h6>{requestData?.userId?.name || ""}</h6>
          <p>{requestData?.userId?.email || ""}</p>
        </div>
      </div>
      <div className='request-status-grid'>
        <div className='request-status'>
          <h6 className='confirm'>Confirmed</h6>
          <p>{getDate(requestData?.updatedAt)}</p>
        </div>
      </div>
      <div className='single-action-btn'>
        <Button
          className='confirm-btn'
          onClick={() =>
            updateReq(requestData?.id, { status: "reject" }, index)
          }>
          Cancel account
        </Button>
      </div>
    </div>
  );
};

const GameCancelRow = ({ requestData, index, openCredPopFn }) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={requestData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{requestData?.gameId?.name || ""}</h6>
          <p>{requestData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={requestData?.userId?.avatar||usericon1} alt='game' />
        <div className='user-name-box'>
          <h6>{requestData?.userId?.name || ""}</h6>
          <p>{requestData?.userId?.email || ""}</p>
        </div>
      </div>
      <div className='request-status-grid'>
        <div className='request-status'>
          <h6 className='cancel'>Canceled</h6>
          <p>{getDate(requestData?.updatedAt)}</p>
        </div>
      </div>
      <div className='single-action-btn'>
        <Button
          className='confirm-btn'
          onClick={() => openCredPopFn(requestData?.id, index)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

const GameCredentialsPopup = ({
  openCredentialsPopup,
  openCredPopFn,
  updateReq,
  selectedGameReq,
  loading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(gameCredentials) });

  const closePopup = () => {
    openCredPopFn();
    reset();
  };

  const saveData = async (value) => {
    const resStatus = await updateReq(
      selectedGameReq.gameId,
      { status: "accept", ...value },
      selectedGameReq.gameIndex
    );
    if (resStatus) {
      closePopup();
      reset();
    }
  };
  return (
    <Modal
      size='lg'
      className='game-request-popup'
      show={openCredentialsPopup}
      onHide={() => closePopup()}
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='new-game-wrapper'>
          <Form onSubmit={handleSubmit(saveData)}>
            <div className='game-request-heading'>
              <h2>Add Game Credentials</h2>
            </div>
            <Form.Group className='form-group'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                name='gameUsername'
                autoComplete='off'
                className={errors.gameUsername ? "error-field" : ""}
                {...register("gameUsername")}
              />
              {errors?.gameUsername ? (
                <p className='error-text'>{errors?.gameUsername?.message}</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className='form-group'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='text'
                name='gamePassword'
                autoComplete='off'
                className={errors.gamePassword ? "error-field" : ""}
                {...register("gamePassword")}
              />
              {errors?.gamePassword ? (
                <p className='error-text'>{errors?.gamePassword?.message}</p>
              ) : (
                ""
              )}
            </Form.Group>

            <div className='popup-footer-btn'>
              <Button className='popup-black' onClick={closePopup}>
                Cancel
              </Button>
              <Button type='submit' className='popup-red'>
                {!loading ? "Add and Confirm" : <Spinner animation='border' />}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
