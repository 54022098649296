import { io } from "socket.io-client";
import { server } from "./keys.js";

const token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))?.access?.token
  : null;
const adminRoomId = "sdjhnhjG^T$%^RTGEYUD^#$%I@#O$*YERHUFB";
console.log({ token, server });
const socket = io(server, {
  reconnectionDelayMax: 1000,
  reconnection: true,
  transports: ["websocket", "xhr-polling"],
  rejectUnauthorized: false,
  query: `token=${token}`,
});

socket.on("connect", () => {
  console.log("connected");
  socket.emit("join", { userId: adminRoomId });
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on("close", tryReconnect);

export { socket };
