import { Button } from "react-bootstrap";
import cashicon from "../../assets/images/table/cash-app-black.svg";
import cryptoicon from "../../assets/images/table/crypto-app-black.svg";
import qricon from "../../assets/images/table/qr-icon.png";
import linkicon from "../../assets/images/table/link-icon.svg";

const WithdrawPopup = ({ data, handleConfirm, handleCancel }) => {
  return (
    <div className="transaction-body">
      <div className="transaction-body-content">
        {data?.payment === "btc" ? (
          <img src={cryptoicon} alt="cashapp" />
        ) : (
          <img src={cashicon} alt="cashapp" />
        )}
        <div className="transaction-heading">
          <h6>Please confirm money withdrawal to client account</h6>
          {data?.payment === "btc" ? (
            <p>BTC {data?.cryptoPrice}</p>
          ) : (
            <p>${data?.amountInUsd}</p>
          )}
        </div>
        <div className="cashtag-link">
          <h6>
            Cashtag: <span>mycashtagforcasinogames</span>
          </h6>
          <span className="link-icon">
            <img src={linkicon} alt="link-icon" />
          </span>
        </div>
        <div className="qr-wrapper">
          <div className="qr-box">
            <img src={qricon} alt="cashapp" />
          </div>
          <div className="qr-info">
            <h6>{data?.userId.name}</h6>
            <p>Scan to pay ${data?.userId.name}</p>
          </div>
        </div>
        <div className="popup-btn-grid">
          <Button
            className="popup-cancel-btn"
            onClick={() => handleCancel(data?.id)}
          >
            Cancel
          </Button>
          <Button
            className="popup-confirm-btn"
            onClick={() => handleConfirm(data?.id)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawPopup;
