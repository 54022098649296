import React, { useState, useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgetPasswordSchema } from "../../validationSchema/authSchema";
import { sendVerificationCode, verifyVerificationCode } from "../../utils/Api";
import toast from "react-hot-toast";
import logo from "../../assets/images/header/logo3d.png";
import "./forget.css";
import UserContext from "../../context/UserContext";

const Forget = ({ handleModal }) => {
  const { resetPassEmailValue, setResetPassEmailValue } =
    useContext(UserContext);
  const [verificationCodeValue, setVerificationCodeValue] = useState("");
  console.log("-", verificationCodeValue);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(ForgetPasswordSchema) });

  const handleForget = async (value) => {
    setIsLoading(true);
    const res = await verifyVerificationCode(value);
    setIsLoading(false);
    if (res.code === 200) {
      toast.success("Code Verified");
      setTimeout(() => {
        navigate(`/reset-password`);
      }, 1000);
    } else {
      toast.error(res.message);
    }
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "email") {
      setResetPassEmailValue(value);
    } else {
      setVerificationCodeValue(value);
    }
    setValue({ [name]: value });
  };

  const sendVerificationcode = async () => {
    if (resetPassEmailValue !== "") {
      const res = await sendVerificationCode(resetPassEmailValue);
      console.log(res.code);
      if (res.code === 200) {
        toast.success("verification code has been send to you email");
      } else {
        toast.error(res.message);
      }
    } else {
      console.log("Email is empty");
    }
  };
  return (
    <div className='forget-password-section'>
      <div className='auth-page'>
        <div className='forget-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='forget-form'>
                  <Link to='/players'>
                    <img src={logo} alt='logo' />{" "}
                  </Link>
                  <h1>Forgot your password?</h1>
                  <p className='auth-para'>
                    To reset your password, please input the verification code
                    that we sent to your email
                  </p>
                  <div className='forget-box'>
                    <Form onSubmit={handleSubmit(handleForget)}>
                      <Form.Group className='form-group'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type='text'
                          name='email'
                          className={errors.email ? "error-field" : ""}
                          {...register("email")}
                          onChange={(e) => handleChange(e)}
                        />
                        <Button
                          type='button'
                          className='send-btn'
                          onClick={sendVerificationcode}>
                          Send
                        </Button>
                        {errors?.email ? (
                          <p className='error-text'>{errors.email.message}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className='form-group'>
                        <Form.Label>Verification code</Form.Label>
                        <Form.Control
                          type='number'
                          className={
                            errors.verificationCode ? "error-field" : ""
                          }
                          {...register("verificationCode")}
                        />
                        {errors?.verificationCode ? (
                          <p className='error-text'>
                            {errors.verificationCode.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <div className='login-button'>
                        <Button className='l-btn' type='submit'>
                          {isLoading ? <Spinner animation='border' /> : "Next"}
                        </Button>
                      </div>
                    </Form>
                    <p className='already'>
                      <Link to='/' className='forget-link'>
                        Back to Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
