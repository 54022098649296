import { Modal, Button, Spinner } from "react-bootstrap";
import "./delete-popup.css";

const DeletePopup = ({
  handleDeletePopup,
  data,
  deletePopup,
  deleteHeadeing,
  deleteSubHeading,
  handleDelete,
  deleteLoading,
  deleteSuccess
}) => {
  return (
    <div className="delete-popup-wrapper">
      <Modal
        size="lg"
        className="delete-popup"
        show={deletePopup}
        onHide={() => handleDeletePopup()}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="delete-popup-content">
            <h6>{deleteHeadeing}</h6>
            <p>{deleteSubHeading}</p>
            {deleteSuccess && <p className="text-success">{deleteSuccess}</p>}
            <div className="delete-popup-footer">
              {" "}
              <Button variant="secondary" onClick={handleDeletePopup}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleDelete(data?.id)}>
                {!deleteLoading ? 'Confirm' : <Spinner animation="border"/>}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeletePopup;
