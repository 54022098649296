import { useState, useEffect } from "react";
//import { useForm } from "react-hook-form";
import AdminLayout from "../layout/layout";
// import PageHeader from "../pageheader/pageheader";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Table } from "react-bootstrap";
import { getKYCRequest, updatekycRequest } from "../../utils/Api";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getDate } from "../../utils/index.js";
import rightArrow from "../../assets/images/table/right-arrow.svg";
import "./clients.css";
import { useCallback } from "react";
import TableLoader from "../loader/tableloader";
import PagePaginationNew from "../pagination/PaginationNew";
import NoData from "../noData";
import { toast } from "react-hot-toast";

const KYCPage = () => {
  const [requestkycList, setRequestkycList] = useState([]);
  const [requestkycView] = useState("idle");
  const initialPaginationValue = {
    limit: 0,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    status: requestkycView,
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const [selectedkycReq, setSelectedkycRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);

  const handleKycPopup = (kycId, kycIndex) => {
    if (kycId && kycIndex !== -1) {
      setSelectedkycRequest({ ...kycId, kycIndex });
    }
    setOpenKycPopup(!openKycPopup);
  };

  useEffect(() => {
    const getkycRequestData = async () => {
      setMainLoading(true);
      const res = await getKYCRequest(paginationValue);
      setMainLoading(false);
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.result;
        setRequestkycList(results || []);
        const pageData = {
          limit,
          page,
          totalPages,
          totalResults,
          status: requestkycView,
        };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getkycRequestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValue?.page, requestkycView]);

  // const handlekycRequestTab = (tab) => {
  //   setPaginationValue({ ...paginationValue, page: 1,status:tab });
  //   setRequestkycView(tab);
  // };

  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  const updateReq = async (kycId, payload, index) => {
    setLoading(true);
    const res = await updatekycRequest(kycId, payload);
    setLoading(false);
    if (res.code === 201) {
     if (payload.status==="reject"){
      toast.error('Kyc rejected.')
     }
      else{
        toast.success('Kyc approved.')
      }
      const copyRequestkycList = JSON.parse(JSON.stringify(requestkycList));
      copyRequestkycList.splice(index, 1);
      setRequestkycList(copyRequestkycList);
      handleKycPopup()
      return true;
    }else{
      toast.error('Unable to update KYC.')
    }
    return false;
  };

  const searchkycsReq = useCallback(
    async (searchValue) => {
      const copyPaginationValue = { ...paginationValue };
      copyPaginationValue.searchValue = searchValue;
      const res = await getKYCRequest(copyPaginationValue);
      if (res.status === 200) {
        const { results } = res?.data?.kycRequest;
        setRequestkycList(results || []);
        // setPaginationValue(copyPaginationValue)
      }
    },
    [paginationValue]
  );
  return (
    <AdminLayout>
      <div className='client-page'>
        {/* <PageHeader pageTitle='KYC Requests' /> */}
        <div className='container'>
          <div className='admin-content-wrapper'>
          <div className='admin-section-title'>
              <h3>KYC Requests</h3>
            </div>
            <SearchBoxComponent searchFunction={searchkycsReq} />
            {!mainLoading ? (
              <>
                {requestkycList?.length > 0 ? (
                  <>
                    <div className='admin-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Gender</th>
                                <th>Date of Birth</th>
                                <th>Street Address</th>
                                <th>Address</th>
                                <th>
                                   Details
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {requestkycList &&
                                requestkycList.length > 0 &&
                                requestkycList.map((element, index) => (
                                  <tr key={element?.id ?? element?._id}>
                                    <td>
                                      <div className='user-name'>
                                        {element?.firstName}
                                      </div>
                                    </td>
                                    <td>{element?.lastName}</td>
                                    <td>{element?.gender || 0}</td>
                                    <td>{getDate(element?.birthDate)}</td>
                                    <td>{element?.streetAdress || ""}</td>
                                    <td>{element?.address || ""}</td>
                                    <td
                                      onClick={() =>
                                        handleKycPopup(element, index)
                                      } style={{cursor:'pointer'}}>
                                      <img src={rightArrow} alt='arrow' />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          {requestkycList?.totalPages > 1 && (
                            <PagePaginationNew
                              page={paginationValue?.activeindex}
                              totalpages={requestkycList?.totalPages}
                              handlePagination={handlePagination}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData heading='No KYC Request Data' />
                )}
              </>
            ) : (
              <TableLoader />
            )}
          </div>
        </div>
        {openKycPopup && (
          <KycPopup
            openKycPopup={openKycPopup}
            handleKycPopup={handleKycPopup}
            updateReq={updateReq}
            selectedkycReq={selectedkycReq}
            loading={loading}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default KYCPage;

const KycPopup = ({
  openKycPopup,
  handleKycPopup,
  updateReq,
  selectedkycReq,
  loading,
}) => {
  const [rejectReason, setRejectReason] = useState("");
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const closePopup = () => {
    handleKycPopup();
  };
  console.log(selectedkycReq)
  return (
    <Modal
      size='lg'
      className='game-request-popup'
      show={openKycPopup}
      onHide={() => closePopup()}
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='transaction-body'>
          <div className='transaction-body-content'>
            <div className='cashtag-heading'>
              <div className='transaction-heading'>
                <h6>Kyc Request</h6>
              </div>
            </div>
            <div className='cashtag-kyc-grid'>
              <div className='cashtag-link'>
                <h6>
                  First Name: <span>{selectedkycReq?.firstName || ""}</span>
                </h6>
              </div>
              <div className='cashtag-link'>
                <h6>
                  Last Name: <span>{selectedkycReq?.lastName || ""}</span>
                </h6>
              </div>
            </div>
            <div className='cashtag-kyc-grid'>
              <div className='cashtag-link'>
                <h6>
                  Gender : <span>{selectedkycReq?.gender || ""}</span>
                </h6>
              </div>
              <div className='cashtag-link'>
                <h6>
                  Date of Birth:{" "}
                  <span>{getDate(selectedkycReq?.birthDate)}</span>
                </h6>
              </div>
            </div>
            <div className='cashtag-kyc-grids'>
              <div className='cashtag-link'>
                <h6>
                  Address: <span>{selectedkycReq?.address || ""}</span>
                </h6>
              </div>
            </div>
            <div className='cashtag-kyc-grids'>
              <div className='cashtag-link'>
                <h6>
                  Street Address:{" "}
                  <span>{selectedkycReq?.streetAddress || ""}</span>
                </h6>
              </div>
            </div>
            <div className='cashtag-kyc-grid'>
              <div className='cashtag-link'>
                <h6>
                  City: <span>{selectedkycReq?.city || ""}</span>
                </h6>
              </div>

              <div className='cashtag-link'>
                <h6>
                  State: <span>{selectedkycReq?.state || ""}</span>
                </h6>
              </div>
            </div>
            <div className='cashtag-kyc-grids'>
              <div className='cashtag-link'>
                <h6>
                  Country: <span>{selectedkycReq?.country || ""}</span>
                </h6>
              </div>
            </div>

            <div className='cashtag-kyc-id'>
              <div className='qr-wrapper'>
                <p>Front Side</p>
                <div className='corner-button'>
                  <div className='corner-bottom-button'>
                    <div className='qr-box'>
                      <img src={selectedkycReq?.IDimageFront} alt='cashapp' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='qr-wrapper'>
                <p>Back Side</p>
                <div className='corner-button'>
                  <div className='corner-bottom-button'>
                    <div className='qr-box'>
                      <img src={selectedkycReq?.IDimageBack} alt='cashapp' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {openRejectPopup && (
            <div className='cashtag-kyc-grids rejectbox mb-5'>
              <div className='reject-link'>
              <label>Reject Message</label>
              <textarea  placeholder="Rejection Message"
                value={rejectReason}

                onChange={(e) => setRejectReason(e.target.value)}
        className="form-control" />
        <Button
                  className='popup-confirm-btn mt-3'
                  onClick={() => {
                    if (!loading) {
                      updateReq(
                        selectedkycReq?.id,
                        { status: "reject", user: selectedkycReq.userId , rejectionReason: rejectReason },
                        selectedkycReq.kycIndex
                      );
                    }
                  }}
                  
                  >
                  {loading ? <Spinner animation='border' /> : "Reject Request"}
                </Button>
              </div>
            </div>
            )}

{!openRejectPopup && (
            <div className='kyc-btn-grid'>
              <div className='popup-btn-grid'>
              <Button
                  className='reject-btn'
                  onClick={() => setOpenRejectPopup(!openRejectPopup)}>
                 Reject Request
                </Button>
                
                <Button
                  className='popup-confirm-btn'
                  onClick={() => {
                    if (!loading) {
                      updateReq(
                        selectedkycReq?.id,
                        { status: "accept", user: selectedkycReq.userId },
                        selectedkycReq.kycIndex
                      );
                    }
                  }}>
                  {loading ? <Spinner animation='border' /> : "Accept Request"}
                </Button>
                {/* <Button
                  className='popup-cancel-btn'
                  onClick={() => closePopup()}>
                  Cancel
                </Button> */}
              </div>
            </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
