import { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchBoxComponent from '../search/searchBoxComponent';
import {
  Button,
  Modal,
  Form,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from 'react-bootstrap';
import editicon from '../../assets/images/offer/edit-icon.svg';
import deleteicon from '../../assets/images/offer/delete-icon.svg';
import './my-game.css';
import plus from '../../assets/images/wallet/plus-red-icon.svg';
import remote from '../../assets/images/wallet/gameremote.svg';
import AdminLayout from '../layout/layout';
import DeletePopup from '../delete-popup/delete-popup';
import cross from '../../assets/images/setting/remove.png';
import NoData from '../noData/index';

import {
  createGame,
  getAllUsers,
  getBrands,
  createBrand,
  getGameLists,
  getBrandsListOnly,
  updateSingleGame,
  updateBrand,
} from '../../utils/Api';
import {
  createGameSchema,
  createBrandSchema,
} from '../../validationSchema/authSchema';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter } from '../../utils';
import PagePaginationNew from '../pagination/PaginationNew';
import TableLoader from '../loader/tableloader';

const SelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 44,
    width: '100%',
    backgroundColor: 'transparent',
    border: '1px solid rgb(124 116 142 / 20%) !important',
    borderRadius: '4px',
    color: '#0E0D27',
    boxShadow: 'none !important',
    position: 'realtive',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff !important' : '#0E0D27',
    padding: 8,
    backgroundColor: 'transparent !important',
    poition: 'absolute',
  }),
};

const initialObj = {
  first: 1,
  last: '',
  next: 2,
  prev: 1,
  activeindex: 1,
  skip: 0,
  limit: 12,
};

const MyGames = () => {
  const [gameInfo, setGameInfo] = useState(false);
  const [newGame, setNewGame] = useState(false);
  const [newBrand, setNewBrand] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [liveGameList, setLiveGamesList] = useState([]);
  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const [liveBrandList, setLiveBrandList] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [tab, setTab] = useState('games');
  const [editValue, setEditValue] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [selectedGameData, setselectedGameData] = useState({});
  const [loading, setLoading] = useState(false);

  const getMyBrandList = async (paginationObj, step) => {
    const result = await getBrands(setLiveBrandList, paginationStep);
    if (result && step === 'first') {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      setPaginationStep(copyObj);
    }
  };

  const getMyGameList = useCallback(
    async (paginationObj, step) => {
      setLoading(true);
      const result = await getGameLists(setLiveGamesList, paginationStep);
      setLoading(false);
      if (result && step === 'first') {
        let copyObj = { ...paginationObj };
        copyObj.last = result?.totalPages;
        setPaginationStep(copyObj);
      }
    },
    [paginationStep]
  );

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    // getMyGameList(copyobj);
    setPaginationStep(copyobj);
  };

  const searchGameList = useCallback(
    async (value) => {
      console.log({ value });
      const searchString = paginationStep;
      searchString.name = value;
      await getMyGameList(setLiveGamesList, searchString);
    },
    [getMyGameList, paginationStep]
  );

  const searchBrandList = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      await getMyBrandList(setLiveBrandList, searchString);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationStep]
  );

  const handleGameInfo = (data) => {
    setselectedGameData(data);
    setGameInfo(!gameInfo);
  };

  const handleNewGame = (update) => {
    if (update) {
      getMyGameList(paginationStep);
    }
    setNewGame(!newGame);
  };

  const handleNewBrand = (update) => {
    if (update) {
      getMyBrandList(paginationStep);
    }
    setNewBrand(!newBrand);
  };

  useEffect(() => {
    const getBrandsList = async () => {
      const res = await getBrandsListOnly({});
      if (res.status === 200) {
        let arr = [];
        res.data.brands &&
          res.data.brands.length > 0 &&
          res.data.brands.forEach((el) => {
            let obj = {};
            obj.label = el?.name;
            obj.value = el?.id;
            arr.push(obj);
          });
        setBrandOptions(arr);
      }
    };

    const manuplateUsers = (data) => {
      let arr = [];
      data.results &&
        data.results.length > 0 &&
        data.results.forEach((el) => {
          let obj = {};
          obj.label = el?.name;
          obj.value = el?.id;
          arr.push(obj);
        });
      setOwnerOptions(arr);
    };
    const getUsers = async () => {
      await getAllUsers(
        manuplateUsers,
        { limit: 100000, activeindex: 1 },
        ['distributor']
      );
    };
    getBrandsList();
    getUsers();
  }, []);

  const editData = (view, data) => {
    setEditValue(data);
    if (view === 'games') {
      handleNewGame();
    } else {
      handleNewBrand();
    }
  };

  const handleDeletePopup = (id) => {
    setDeleteId({ id });
    if (deletePopup) {
      setDeletePopup(false);
    } else {
      setDeletePopup(true);
    }
  };

  const handleDelete = async (id) => {
    const formData = new FormData();
    formData.append('delete', JSON.stringify({ isDelete: true }));
    let res;
    setDeleteLoading(true);
    if (tab === 'games') {
      res = await updateSingleGame(id, formData);
    } else {
      res = await updateBrand(id, formData);
    }
    setDeleteLoading(false);
    if (res.status === 201) {
      if (tab === 'games') {
        getMyGameList(paginationStep);
        setDeleteSuccess('Game deleted');
      } else {
        getMyBrandList(paginationStep);
        setDeleteSuccess('Brand deleted');
      }
      setTimeout(() => {
        setDeletePopup(false);
        setDeleteSuccess('');
      }, 1500);
    }
  };

  useEffect(()=>{
    getMyGameList()
    getMyBrandList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <AdminLayout>
      <div className='my-game-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Games</h3>
            </div>
            <div className='admin-tab-section'>
              <Tabs defaultActiveKey='games' onSelect={(tab) => setTab(tab)}>
                <Tab eventKey='games' title='All Games'>
                  <div className='admin-games'>
                    <div className='new-game-btn-wrapper'>
                      <SearchBoxComponent searchFunction={searchGameList} />
                      <Button onClick={handleNewGame} className='new-game-btn'>
                        <img src={plus} alt='icon' /> Add New Game
                      </Button>
                    </div>
                    {!loading ? (
                      <>
                        {liveGameList.results &&
                        liveGameList.results.length > 0 ? (
                          <>
                            <div className='admin-games-content'>
                              <div className='admin-games-grid'>
                                {liveGameList.results.map((element, index) => {
                                  return (
                                    <>
                                      <div
                                        className='admin-games-box'
                                        key={element?.id ?? element?._id}>
                                        <div className='admin-games-image'>
                                          <img src={element?.logo} alt='' />
                                        </div>
                                        <div className='edit-offer-menu'>
                                          <Dropdown align='end'>
                                            <Dropdown.Toggle>
                                              <MenuSVG />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                as='button'
                                                onClick={() =>
                                                  editData('games', element)
                                                }>
                                                Edit{' '}
                                                <img
                                                  src={editicon}
                                                  alt='icon'
                                                />
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as='button'
                                                onClick={() =>
                                                  handleDeletePopup(element?.id)
                                                }>
                                                Delete{' '}
                                                <img
                                                  src={deleteicon}
                                                  alt='icon'
                                                />
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <div className='recent-game-detail'>
                                          <div className='recent-game-detail-bottom'>
                                            <div className='recent-game-detail-left'>
                                              <p>{element?.name}</p>
                                              <div className='recent-game-detail-img'>
                                                <img src={remote} alt='' />
                                                <span>
                                                  {element?.playCount} Games
                                                </span>
                                              </div>
                                            </div>
                                            <div className='recent-game-detail-right'>
                                              <Button
                                                className='red-btn'
                                                onClick={() =>
                                                  handleGameInfo(element)
                                                }>
                                                Info
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NoData heading='No Games Data' />
                        )}

                        {liveGameList?.totalPages > 1 && (
                          <PagePaginationNew
                            page={liveGameList?.page}
                            totalpages={liveGameList?.totalPages}
                            handlePagination={handlePagenation}
                          />
                        )}
                      </>
                    ) : (
                      <TableLoader />
                    )}
                  </div>
                </Tab>
                <Tab
                  eventKey='brands'
                  title='Brands'
                  onClick={() => {
                    getMyBrandList(initialObj, 'first');
                  }}>
                  <div className='admin-brands'>
                    <div className='new-game-btn-wrapper'>
                      <SearchBoxComponent searchFunction={searchBrandList} />
                      <Button onClick={handleNewBrand} className='new-game-btn'>
                        <img src={plus} alt='icon' /> Add New Brand
                      </Button>
                    </div>
                    {!loading ? (
                      <>
                        {liveBrandList.results &&
                        liveBrandList.results.length > 0 ? (
                          <>
                            <div className='admin-games-content'>
                              <div className='admin-games-grid'>
                                {liveBrandList.results.map((element, index) => {
                                  return (
                                    <>
                                      <div
                                        className='admin-games-box'
                                        key={element?.id ?? element?._id}>
                                        <div className='admin-games-image'>
                                          <img src={element?.logo} alt='' />
                                        </div>
                                        <div className='edit-offer-menu'>
                                          <Dropdown align='end'>
                                            <Dropdown.Toggle>
                                              <MenuSVG />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                as='button'
                                                onClick={() =>
                                                  editData('brands', element)
                                                }>
                                                Edit{' '}
                                                <img
                                                  src={editicon}
                                                  alt='icon'
                                                />
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as='button'
                                                onClick={() =>
                                                  handleDeletePopup(element?.id)
                                                }>
                                                Delete{' '}
                                                <img
                                                  src={deleteicon}
                                                  alt='icon'
                                                />
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>

                                        <div className='recent-game-detail'>
                                          <div className='recent-game-detail-bottom'>
                                            <div className='recent-game-detail-left'>
                                              <p>{element?.name}</p>
                                              <div className='recent-game-detail-img'>
                                                {/* <img src={remote} alt="" /> <span>125 Games</span> */}
                                              </div>
                                            </div>
                                            <div className='recent-game-detail-right'>
                                              <Button
                                                className='red-btn'
                                                onClick={handleGameInfo}>
                                                Info
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <NoData heading='No Brands Data' />
                        )}
                        {liveBrandList?.totalPages > 1 && (
                          <PagePaginationNew
                            page={liveBrandList?.page}
                            totalpages={liveBrandList?.totalPages}
                            handlePagination={handlePagenation}
                          />
                        )}
                      </>
                    ) : (
                      <TableLoader />
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <GameInfoPopup
        handleGameInfo={handleGameInfo}
        gameInfo={gameInfo}
        data={selectedGameData}
      />
      {newGame && (
        <AddNewGamePopup
          handleNewGame={handleNewGame}
          newGame={newGame}
          brandOptions={brandOptions}
          editValue={editValue}
          setBrandOptions={setBrandOptions}
          setEditValue={setEditValue}
        />
      )}
      {newBrand && (
        <AddNewBrandPopup
          handleNewBrand={handleNewBrand}
          newBrand={newBrand}
          ownerOptions={ownerOptions}
          editValue={editValue}
        />
      )}
      {deletePopup && (
        <DeletePopup
          deletePopup={deletePopup}
          handleDeletePopup={handleDeletePopup}
          deleteHeadeing='Please confirm if you want to delete this Game'
          deleteSubHeading='After you confirm, this Game will be deleted.'
          data={deleteId}
          handleDelete={handleDelete}
          deleteSuccess={deleteSuccess}
          deleteLoading={deleteLoading}
        />
      )}
    </AdminLayout>
  );
};

export default MyGames;

const GameInfoPopup = ({ gameInfo, handleGameInfo, data }) => {
  //console.log("Info Popup Data------>", data);

  return (
    <Modal
      size='lg'
      className='game-info-popup'
      show={gameInfo}
      // onClick={() => handleGameInfo()}
      onClick={handleGameInfo}
      centered>
      <Modal.Header closeButton>
        <div className='game-info-banner'>
          <img src={data?.logo} alt='banner' className='img-fluid' />
          <div className='game-info-name'>
            <h2>{data?.name}</h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='game-info-wrapper'>
          <div className='game-info-content'>
            <h6>Game info</h6>
            <div className='game-info-grid'>
              <div className='game-info-left'>
                <p>Games type</p>
              </div>
              <div className='game-info-right'>
                <p>{data?.gameCategory}</p>
              </div>
            </div>
            <div className='game-info-grid'>
              <div className='game-info-left'>
                <p>Devices</p>
              </div>
              <div className='game-info-right'>
                <p>
                  {data.device &&
                    data.device.length > 0 &&
                    data.device.map((element, index) => {
                      return <>{element},</>;
                    })}
                </p>
              </div>
            </div>
          </div>

          <div className='popup-footer-btn'>
            <Button className='popup-black' onClick={handleGameInfo}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const AddNewGamePopup = ({
  newGame,
  handleNewGame,
  brandOptions,
  editValue,
  setEditValue,
}) => {
  const deviceoptions = [
    { value: 'windows', label: 'Windows' },
    { value: 'ios', label: 'IOS' },
    { value: 'pc', label: 'PC' },
    { value: 'android', label: 'Android' },
  ];
  const categoryOptions = [
    { value: 'keno', label: 'Keno' },
    { value: 'fish', label: 'Fish' },
    { value: 'slots', label: 'Slots' },
    { value: 'others', label: 'Others' },
  ];

  const [selectedDevice, setSelectedDevice] = useState(
    editValue
      ? editValue.device.map((el) => ({
          label: capitalizeFirstLetter(el || ''),
          value: el || '',
        }))
      : []
  );
  const [selectedCategory, setSelectedCategory] = useState(
    editValue
      ? {
          label: capitalizeFirstLetter(editValue?.gameCategory || ''),
          value: editValue?.gameCategory,
        }
      : null
  );
  const [previousLogoImg, setPreviousLogoImg] = useState(
    editValue ? [editValue.logo] : []
  );
  const [selectedBrand, setSelectedBrand] = useState(
    editValue
      ? { label: editValue?.brandName?.name, value: editValue?.brandName?.id }
      : null
  );
  const [previewBannerImg, setPreviewBannerImg] = useState([]);
  const [previousBannerImages, setPreviousBannerImages] = useState(
    editValue ? editValue?.bannerImages : []
  );
  const [previewLogoImg, setpreviewLogoImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(createGameSchema) });

  const handleImageChange = (e) => {
    const { name } = e.target;
    if (name === 'bannerImage') {
      setPreviewBannerImg([...previewBannerImg, ...e.target.files]);
    }
    if (name === 'logo') {
      setpreviewLogoImg([...e.target.files]);
    }
  };
  const saveData = async (value) => {
    const formData = new FormData();
    let payload = { ...value };
    // if (!selectedBrand) {
    //   setError('brand', { message: 'Select Brand.' });
    //   return;
    // }
    if (!selectedCategory) {
      setError('category', { message: 'Select Category.' });
      return;
    }
    if (selectedDevice.length === 0) {
      setError('device', { message: 'Select Devices.' });
      return;
    }
    if (
      previewBannerImg.length + previousBannerImages.length > 10 ||
      previewBannerImg.length + previousBannerImages.length < 1
    ) {
      setError('bannerImage', {
        message: 'There should be atleat 1 image or maximum 10 images.',
      });
      return;
    }
    if (previewLogoImg.length + previousLogoImg.length !== 1) {
      setError('logoImage', { message: 'Please uplaod logo image.' });
      return;
    }
    payload.brandName = selectedBrand.value;
    payload.gameCategory = selectedCategory.value;
    payload.device = selectedDevice.map((el) => el.value);
    previewBannerImg.forEach((el) => {
      formData.append('bannerImages', el);
    });
    formData.append('logo', previewLogoImg[0]);
    formData.append('formValues', JSON.stringify(payload));
    let res;
    setLoading(true);
    if (!editValue) {
      res = await createGame(formData);
    } else {
      formData.append('logoCountPrev', previousLogoImg);
      formData.append('bannerImgCountPrev', previousBannerImages);
      res = await updateSingleGame(editValue.id, formData);
    }
    setLoading(false);
    if (res.status === 201) {
      setSuccessMsg('Uploaded succesfully.');
      setTimeout(() => {
        handleNewGame(true);
        setSuccessMsg('');
        reset();
        setSelectedDevice([]);
        setSelectedCategory(null);
        setPreviewBannerImg([]);
        setpreviewLogoImg([]);
      }, 1500);
    } else {
      toast.error('Unable to uplaod data. Please try again');
    }
  };

  const handleRemoveImage = (index, imgCheck, prevCheck) => {
    if (imgCheck) {
      if (!prevCheck) {
        const copyBannerImg = [...previewBannerImg];
        copyBannerImg.splice(index, 1);
        setPreviewBannerImg(copyBannerImg);
      } else {
        const copyPreviewyBannerImg = [...previousBannerImages];
        copyPreviewyBannerImg.splice(index, 1);
        setPreviousBannerImages(copyPreviewyBannerImg);
      }
    } else {
      if (!prevCheck) {
        setpreviewLogoImg([]);
      } else {
        setPreviousLogoImg([]);
      }
    }
  };
  const closePopup = () => {
    handleNewGame();
    setEditValue(null);
  };

  return (
    <Modal
      size='lg'
      className='game-request-popup'
      show={newGame}
      onHide={() => closePopup()}
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='new-game-wrapper'>
          <Form onSubmit={handleSubmit(saveData)}>
            <div className='game-request-heading'>
              <h2>{editValue ? 'Edit' : 'Add New'} Game</h2>
            </div>
            <Form.Group className='form-group'>
              <Form.Label>Game Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                autoComplete='off'
                className={errors.name ? 'error-field' : ''}
                defaultValue={editValue?.name}
                {...register('name')}
              />
              {errors?.name ? (
                <p className='error-text'>{errors?.name?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className='form-group'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                type='text'
                name='description'
                autoComplete='off'
                className={errors.description ? 'error-field' : ''}
                defaultValue={editValue?.description}
                {...register('description')}
              />
              {errors?.description ? (
                <p className='error-text'>{errors?.description?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>
            <div className='brand-input'>
              <Form.Group className='form-group'>
                <Form.Label>Brand Name</Form.Label>
                <Select
                  placeholder='Select Brand'
                  options={brandOptions}
                  styles={SelectStyles}
                  onChange={setSelectedBrand}
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={
                    editValue
                      ? {
                          label: editValue?.brandName?.name,
                          value: editValue?.brandName?.id,
                        }
                      : ''
                  }
                />
                {errors?.brand ? (
                  <p className='error-text'>{errors?.brand?.message}</p>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group className='form-group'>
                <Form.Label>Download Link</Form.Label>
                <Form.Control
                  type='text'
                  name='downloadUrl'
                  autoComplete='off'
                  className={errors.downloadUrl ? 'error-field' : ''}
                  defaultValue={editValue?.downloadUrl}
                  {...register('downloadUrl')}
                />
                {errors?.downloadUrl ? (
                  <p className='error-text'>{errors?.downloadUrl?.message}</p>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <Form.Group className='form-group'>
              <Form.Label>category</Form.Label>
              <Select
                placeholder='Select Category'
                options={categoryOptions}
                styles={SelectStyles}
                onChange={setSelectedCategory}
                className='basic-single'
                classNamePrefix='select'
                defaultValue={
                  editValue && {
                    label: capitalizeFirstLetter(editValue?.gameCategory || ''),
                    value: editValue?.gameCategory || '',
                  }
                }
              />
              {errors?.category ? (
                <p className='error-text'>{errors?.category?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group className='form-group'>
              <Form.Label>Device Supported</Form.Label>
              <Select
                placeholder='Select Device'
                options={deviceoptions}
                styles={SelectStyles}
                onChange={setSelectedDevice}
                className='basic-single'
                classNamePrefix='select'
                isMulti
                defaultValue={
                  editValue &&
                  editValue?.device?.map((el) => ({
                    label: capitalizeFirstLetter(el || ''),
                    value: el || '',
                  }))
                }
              />
              {errors?.device ? (
                <p className='error-text'>{errors?.device?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>

            <div className='upload-game-images'>
              <Form.Group className='form-group'>
                <Form.Label>Upload Banner</Form.Label>
                <div className='upload-game-banner'>
                  {previewBannerImg.length === 0 &&
                    previousBannerImages.length === 0 && (
                      <Form.Control
                        type='file'
                        id='uploadBanner'
                        name='bannerImage'
                        accept='.png, .jpg, .jpeg'
                        multiple
                        onChange={handleImageChange}
                      />
                    )}

                  <div className='no-image-area'>
                    {previewBannerImg.length > 0 || editValue ? (
                      <>
                        {previewBannerImg.length > 0 &&
                          previewBannerImg.map((el, i) => (
                            <div className='preview-banner'>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() =>
                                  handleRemoveImage(i, true, false)
                                }
                              />
                              <img
                                src={el ? window.URL.createObjectURL(el) : ''}
                                alt='bannerImg'
                                height='100px'
                                width='100px'
                                className='previewImg'
                              />
                            </div>
                          ))}
                        {editValue &&
                          previousBannerImages.length > 0 &&
                          previousBannerImages?.map((el, i) => (
                            <div className='preview-banner'>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() => handleRemoveImage(i, true, true)}
                              />
                              <img
                                src={el || ''}
                                alt='bannerImg'
                                height='100px'
                                width='100px'
                                className='previewImg'
                              />
                            </div>
                          ))}

                        <Form.Label htmlFor='uploadBanner'>
                          {((previousBannerImages.length < 10 &&
                            previousBannerImages.length > 0) ||
                            (previewBannerImg.length < 10 &&
                              previewBannerImg.length > 0)) && (
                            <>
                              <span className='add-more'>
                                <i class='las la-plus'></i> Add more
                                <Form.Control
                                  type='file'
                                  id='uploadBanner'
                                  name='bannerImage'
                                  accept='.png, .jpg, .jpeg'
                                  multiple
                                  onChange={handleImageChange}
                                />
                              </span>
                            </>
                          )}
                        </Form.Label>
                      </>
                    ) : (
                      <>
                        {' '}
                        <h6>
                          <Form.Label htmlFor='uploadBanner'>
                            Drag & Drop or <span> Upload </span> the banner.
                          </Form.Label>
                        </h6>
                      </>
                    )}
                  </div>
                </div>
                {errors?.bannerImage ? (
                  <p className='error-text'>{errors?.bannerImage?.message}</p>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className='upload-logo-thumnail'>
                <Form.Group className='form-group'>
                  <Form.Label>Upload Logo</Form.Label>
                  <div className='upload-game-thumnail'>
                    <Form.Control
                      type='file'
                      id='uploadThumbnail'
                      name='logo'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleImageChange}
                    />
                    <Form.Label htmlFor='uploadThumbnail'>
                      <div className='no-image-area'>
                        {previewLogoImg.length > 0 || editValue?.logo ? (
                          <>
                            {' '}
                            {previewLogoImg.length > 0 && (
                              <>
                                <img
                                  src={cross}
                                  alt='cross'
                                  className='crossImg'
                                  onClick={() =>
                                    handleRemoveImage(0, false, false)
                                  }
                                />
                                <img
                                  src={window.URL.createObjectURL(
                                    previewLogoImg[0]
                                  )}
                                  alt='logo-img'
                                />
                              </>
                            )}
                            {previousLogoImg.length > 0 && (
                              <>
                                <img
                                  src={cross}
                                  alt='cross'
                                  className='crossImg'
                                  onClick={() =>
                                    handleRemoveImage(0, false, true)
                                  }
                                />
                                <img src={previousLogoImg[0]} alt='logo-img' />
                              </>
                            )}
                            <div></div>
                          </>
                        ) : (
                          <h6>
                            Drag & Drop or <span> Upload </span> the logo.
                          </h6>
                        )}
                      </div>
                    </Form.Label>
                  </div>
                  {errors?.logoImage ? (
                    <p className='error-text'>{errors?.logoImage?.message}</p>
                  ) : (
                    ''
                  )}
                  {successMsg && <p className='text-success'>{successMsg}</p>}
                </Form.Group>
              </div>
            </div>

            <div className='popup-footer-btn'>
              <Button className='popup-black' onClick={closePopup}>
                Cancel
              </Button>
              <Button type='submit' className='popup-red'>
                {!loading ? (
                  editValue ? (
                    'Update'
                  ) : (
                    'Add'
                  )
                ) : (
                  <Spinner animation='border' />
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const AddNewBrandPopup = ({
  newBrand,
  handleNewBrand,
  ownerOptions,
  editValue,
}) => {
  const [previewBannerImg, setPreviewBannerImg] = useState([]);
  const [previewLogoImg, setpreviewLogoImg] = useState([]);
  const [previousBannerImages, setPreviousBannerImages] = useState(
    editValue ? editValue?.bannerImages : []
  );
  const [previousLogoImg, setPreviousLogoImg] = useState(
    editValue ? [editValue.logo] : []
  );
  const [, setSelectedOwner] = useState(
    editValue?.owner
      ? { lable: editValue?.owner?.name || '', value: editValue?.owner?.id }
      : null
  );
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(createBrandSchema) });

  const handleImageChange = (e) => {
    const { name } = e.target;
    if (name === 'bannerImage') {
      setPreviewBannerImg([...previewBannerImg, ...e.target.files]);
    }
    if (name === 'logo') {
      setpreviewLogoImg([...e.target.files]);
    }
  };


  const handleSave = async (value) => {
    const formData = new FormData();
    let payload = { ...value };
    // if (!selectedOwner) {
    //   setError('owner', { message: 'Select owner.' });
    //   return;
    // }
    if (
      previewBannerImg.length + previousBannerImages.length > 10 ||
      previewBannerImg.length + previousBannerImages.length < 1
    ) {
      setError('bannerImage', {
        message: 'There should be atleat 1 image or maximum 10 images.',
      });
      return;
    }
    if (previewLogoImg.length + previousLogoImg.length !== 1) {
      setError('logoImage', { message: 'Please uplaod logo image.' });
      return;
    }
    previewBannerImg.forEach((el) => {
      formData.append('bannerImages', el);
    });
    // payload.owner = selectedOwner.value;
    formData.append('logo', previewLogoImg[0]);
    formData.append('formValues', JSON.stringify(payload));
    setLoading(true);
    let res;
    if (!editValue) {
      res = await createBrand(formData);
    } else {
      formData.append('logoCountPrev', previousLogoImg);
      formData.append('bannerImgCountPrev', previousBannerImages);
      res = await updateBrand(editValue.id, formData);
    }
    setLoading(false);
    if (res?.status === 201) {
      setSuccessMsg('Uploaded succesfully.');
      setTimeout(() => {
        handleNewBrand(true);
        setSuccessMsg('');
        reset();
        setSelectedOwner([]);
        setPreviewBannerImg([]);
        setpreviewLogoImg([]);
      }, 1500);
    } else {
      toast.error('Unable to uplaod data. Please try again');
    }
  };

  const handleRemoveImage = (index, imgCheck, prevCheck) => {
    if (imgCheck) {
      if (!prevCheck) {
        const copyBannerImg = [...previewBannerImg];
        copyBannerImg.splice(index, 1);
        setPreviewBannerImg(copyBannerImg);
      } else {
        const copyPreviewyBannerImg = [...previousBannerImages];
        copyPreviewyBannerImg.splice(index, 1);
        setPreviousBannerImages(copyPreviewyBannerImg);
      }
    } else {
      if (!prevCheck) {
        setpreviewLogoImg([]);
      } else {
        setPreviousLogoImg([]);
      }
    }
  };

  const onClosePopup = () => {
    // setPreviousLogoImg([]);s
    // setPreviousBannerImages([])
    handleNewBrand();
  };
  return (
    <Modal
      size='lg'
      className='game-request-popup'
      show={newBrand}
      onHide={() => onClosePopup()}
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='new-game-wrapper'>
          <Form onSubmit={handleSubmit(handleSave)}>
            <div className='game-request-heading'>
              <h2>{editValue ? 'Edit' : 'Add New'} Brand</h2>
            </div>

            <Form.Group className='form-group'>
              <Form.Label>Brand Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                autoComplete='off'
                defaultValue={editValue?.name}
                className={errors.name ? 'error-field' : ''}
                {...register('name')}
              />
              {errors?.name ? (
                <p className='error-text'>{errors?.name?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className='form-group'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                type='text'
                name='description'
                autoComplete='off'
                className={errors.description ? 'error-field' : ''}
                defaultValue={editValue?.description}
                {...register('description')}
              />
              {errors?.description ? (
                <p className='error-text'>{errors?.description?.message}</p>
              ) : (
                ''
              )}
            </Form.Group>
            {/* <Form.Group className='form-group'>
              <Form.Label>Owner</Form.Label>
              <Select
                placeholder='Select Owner'
                options={ownerOptions}
                styles={SelectStyles}
                className='basic-single'
                onChange={setSelectedOwner}
                classNamePrefix='select'
                defaultValue={
                  editValue?.owner && {
                    label: editValue?.owner?.name || '',
                    value: editValue?.owner?.id,
                  }
                }
              />
              {errors?.owner ? (
                <p className='error-text'>{errors?.owner?.message}</p>
              ) : (
                ''
              )}
            </Form.Group> */}
            <div className='upload-game-images'>
              <Form.Group className='form-group'>
                <Form.Label>Upload Banner</Form.Label>
                <div className='upload-game-banner'>
                  {previewBannerImg.length === 0 &&
                    previousBannerImages.length === 0 && (
                      <Form.Control
                        type='file'
                        id='uploadBanner'
                        name='bannerImage'
                        accept='.png, .jpg, .jpeg'
                        multiple
                        onChange={handleImageChange}
                      />
                    )}

                  <div className='no-image-area'>
                    {previewBannerImg.length > 0 || editValue ? (
                      <>
                        {previewBannerImg.length > 0 &&
                          previewBannerImg.map((el, i) => (
                            <div className='preview-banner'>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() =>
                                  handleRemoveImage(i, true, false)
                                }
                              />
                              <img
                                src={el ? window.URL.createObjectURL(el) : ''}
                                alt='bannerImg'
                                height='100px'
                                width='100px'
                                className='previewImg'
                              />
                            </div>
                          ))}
                        {editValue &&
                          previousBannerImages.length > 0 &&
                          previousBannerImages?.map((el, i) => (
                            <div className='preview-banner'>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() => handleRemoveImage(i, true, true)}
                              />
                              <img
                                src={el || ''}
                                alt='bannerImg'
                                height='100px'
                                width='100px'
                                className='previewImg'
                              />
                            </div>
                          ))}

                        <Form.Label htmlFor='uploadBanner'>
                          {((previousBannerImages?.length < 10 &&
                            previousBannerImages?.length > 0) ||
                            (previewBannerImg?.length < 10 &&
                              previewBannerImg?.length > 0)) && (
                            <>
                              <span className='add-more'>
                                <i class='las la-plus'></i> Add more
                                <Form.Control
                                  type='file'
                                  id='uploadBanner'
                                  name='bannerImage'
                                  accept='.png, .jpg, .jpeg'
                                  multiple
                                  onChange={handleImageChange}
                                />
                              </span>
                            </>
                          )}
                        </Form.Label>
                      </>
                    ) : (
                      <>
                        {' '}
                        <h6>
                          <Form.Label htmlFor='uploadBanner'>
                            Drag & Drop or <span> Upload </span> the banner.
                          </Form.Label>
                        </h6>
                      </>
                    )}
                  </div>
                </div>
                {errors?.bannerImage ? (
                  <p className='error-text'>{errors?.bannerImage?.message}</p>
                ) : (
                  ''
                )}
              </Form.Group>
              <div className='upload-logo-thumnail'>  
                <Form.Group className='form-group'>
                  <Form.Label>Upload Logo</Form.Label>
                  <div className='upload-game-thumnail'>
                    <Form.Control
                      type='file'
                      id='uploadThumbnail'
                      name='logo'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleImageChange}
                    />
                    <Form.Label htmlFor='uploadThumbnail'>
                      <div className='no-image-area'>
                        {previewLogoImg.length > 0 || editValue?.logo ? (
                          <>
                            {' '}
                            {previewLogoImg.length > 0 && (
                              <>
                                <img
                                  src={cross}
                                  alt='cross'
                                  className='crossImg'
                                  onClick={() => handleRemoveImage(0, false)}
                                />
                                <img
                                  src={window.URL.createObjectURL(
                                    previewLogoImg[0]
                                  )}
                                  alt='logo-img'
                                />
                              </>
                            )}
                            {previousLogoImg.length > 0 && (
                              <>
                                <img
                                  src={cross}
                                  alt='cross'
                                  className='crossImg'
                                  onClick={() =>
                                    handleRemoveImage(0, false, true)
                                  }
                                />
                                <img src={previousLogoImg[0]} alt='logo-img' />
                              </>
                            )}
                          </>
                        ) : (
                          <h6>
                            Drag & Drop or <span> Upload </span> the logo.
                          </h6>
                        )}
                      </div>
                    </Form.Label>
                  </div>
                  {errors?.logoImage ? (
                    <p className='error-text'>{errors?.logoImage?.message}</p>
                  ) : (
                    ''
                  )}
                  {successMsg && <p className='text-success'>{successMsg}</p>}
                </Form.Group>
              </div>
            </div>

            <div className='popup-footer-btn'>
              <Button className='popup-black' onClick={onClosePopup}>
                Cancel
              </Button>
              <Button className='popup-red' type='submit'>
                {!loading ? (
                  editValue ? (
                    'Update'
                  ) : (
                    'Add'
                  )
                ) : (
                  <Spinner animation='border' />
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const MenuSVG = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='3' cy='10' r='2' fill='#0E0D27' />
      <circle cx='10' cy='10' r='2' fill='#0E0D27' />
      <circle cx='17' cy='10' r='2' fill='#0E0D27' />
    </svg>
  );
};
