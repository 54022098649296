import { Pagination } from "react-bootstrap";
import "./pagination.css";
//totalPages
const PagePagination = ({
  totalcount,
  totalpages,
  handlePagenation,
  paginationStep,
}) => {
  const { activeindex, first, last, next, prev } = paginationStep || {};

  return (
    <>
      {totalpages > 0 && (
        <div className="pagination-sec">
          <Pagination>
            <Pagination.First onClick={() => handlePagenation(first)} />
            <Pagination.Prev onClick={() => handlePagenation(prev)} />
            {totalpages &&
              [...Array(parseInt(totalpages))].map((elementInArray, index) => (
                <Pagination.Item
                  active={activeindex === index + 1 ? true : false}
                  onClick={() => handlePagenation(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            {/* <Pagination.Ellipsis /> */}
            {/* <Pagination.Item>{totalcount}</Pagination.Item> */}
            <Pagination.Next onClick={() => handlePagenation(next)} />
            <Pagination.Last onClick={() => handlePagenation(last)} />
          </Pagination>
        </div>
      )}
    </>
  );
};

export default PagePagination;
