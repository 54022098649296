import "./pageheader.css";
import coinicon from "../../assets/images/header/coin-icon.svg";
import { changeCurrencyFormat } from "../../utils";

const PageHeader = ({ pageTitle,totalData }) => {
  return (
    <div className="page-header-section">
      <div className="container">
        <div className="page-header">
          <div className="page-header-title">
            <h2>{pageTitle}</h2>
            <p>Total players balance in coins</p>
          </div>
          <div className="wallet-info">
            <div className="total-coins">
              <p>
                <img src={coinicon} alt="coin" /> {changeCurrencyFormat(totalData?.coins||0)}
              </p>
            </div>

            <div className="coins-value-box">
              <p>
                USD: <sapn>${changeCurrencyFormat(totalData?.usdAmount || 0)}</sapn>
              </p>
            </div>
            <div className="coins-value-box">
              <p>
                Bitcoin: <sapn>{totalData?.btcAmount||0}</sapn>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
