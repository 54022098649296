import React, { useState, useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";
import { resetPassword } from "../../utils/Api";
import logo from "../../assets/images/header/logo3d.png";
import toast from "react-hot-toast";
import UserContext from "../../context/UserContext";

const ResetPassword = () => {
  const { resetPassEmailValue, setResetPassEmailValue, setTokens, setUser } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const [pass, setPass] = useState("password");
  const [confirmPass, setConfirmPass] = useState("password");
  const handlePass = () => {
    setPass(!pass);
  };
  const handleConfirmPass = () => {
    setConfirmPass(!confirmPass);
  };

  console.log("values,resetPassEmailValue==>", resetPassEmailValue);
  const handleResetPassword = async (values) => {
    setIsLoading(true);
    // const urlParams = new URLSearchParams(window.location.search)
    // const res = await resetPassword(values, urlParams.get("token"));
    const res = await resetPassword(values, resetPassEmailValue);
    setIsLoading(false);
    if (res.code === 200) {
      toast.success("Password update successfully.");
      console.log("res.tokens---", res.data.tokens);
      localStorage.setItem("token", JSON.stringify(res.data.tokens));
      setTokens(res.data.tokens);
      setUser(res.data.user);
      setTimeout(() => {
        navigate("/");
      }, 1000);
      setResetPassEmailValue("");
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="reset-password-section">
      <div className="auth-page">
        <div className="forget-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="forget-form">
                  <Link to="/players">
                    <img src={logo} alt="logo" />{" "}
                  </Link>
                  <h1>Create a new password</h1>

                  <div className="forget-box">
                    <Form onSubmit={handleSubmit(handleResetPassword)}>
                      <Form.Group className="form-group">
                        <Form.Label>Create a new password</Form.Label>
                        <Form.Control
                          type={`${pass ? `password` : `text`}`}
                          name="password"
                          className={errors.password ? "error-field" : ""}
                          {...register("password")}
                        />
                        <span className="input-icon" onClick={handlePass}>
                          <i
                            class={pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                        {errors?.password ? (
                          <p className="error-text">
                            {errors.password.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Confirm the password</Form.Label>
                        <Form.Control
                          type={`${confirmPass ? `password` : `text`}`}
                          name="confirmPassword"
                          className={
                            errors.confirmPassword ? "error-field" : ""
                          }
                          {...register("confirmPassword")}
                        />
                        <span
                          className="input-icon"
                          onClick={handleConfirmPass}
                        >
                          <i
                            class={
                              confirmPass ? "las la-eye" : "las la-eye-slash"
                            }
                          ></i>
                        </span>
                        {errors?.confirmPassword ? (
                          <p className="error-text">
                            {errors.confirmPassword.message}
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <div className="login-button">
                        <Button type="submit" className="l-btn">
                          {isLoading ? (
                            <Spinner animation="border" />
                          ) : (
                            "Create a new password"
                          )}
                        </Button>
                      </div>
                    </Form>
                    <p className="already">
                      <Link to="/" className="forget-link">
                        Back to Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
