import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";


const roleRoutes = {
  admin: [],
  cashier: ["/game-recharge", "/game-redeem","/settings"],
  customerService: ["/game-request", "/live-chat", "/withdraw", "/deposit","/settings"],
};

const checkRoute = (role, route) => {
    if (role === "admin") {
      return true;
    }
    return roleRoutes[role].includes(route);
  };

const UserRoute = () => {
  const { user } = useContext(UserContext);
  console.log("user?.role",user?.role)
  const {pathname} = useLocation();
  const tokenData = localStorage.getItem("admintoken");
  let checkTokenData = false;
  if (tokenData) {
    const tokenParsed = JSON.parse(tokenData) || {};
    const { token, expires } = tokenParsed?.access;
    if (token && new Date() < new Date(expires)) {
      checkTokenData = true;
      checkTokenData =  checkRoute(user?.role, pathname)
    }
  }
  return checkTokenData ? <Outlet /> : <Navigate to='/' />;
};
export default UserRoute;
