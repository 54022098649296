import {  useState } from "react";
// import { capitalizeFirstLetter } from "../../utils";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import "./playerProfile.css";
// import { getUser } from "../../utils/Api";

const EditGameInfoPopup = ({
  gameInfo,
  handleGameInfo,
  selectedGame,
  updateGameInfo,
}) => {
  console.log("selectedGame", selectedGame);
  const [gameInfoData, setGameInfoData] = useState({
    gameUsername: selectedGame?.gameUsername,
    gamePassword: selectedGame?.gamePassword,
    confirmPassword: "",
  });
  const [pass, setPass] = useState("password");
  const [confirmPass, setConfirmPass] = useState("password");
  const [error, setError] = useState(false);
  const handlePass = () => {
    setPass(!pass);
  };

  const handleConfirmPass = () => {
    setConfirmPass(!confirmPass);
  };

  const updatePassGameInfo = (id, data) => {
    if (data.gamePassword !== data.confirmPassword) {
      setError(true);
      return;
    }

    delete data.confirmPassword;

    updateGameInfo(id, data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGameInfoData({ ...gameInfoData, [name]: value });
  };

  return (
    <Modal
      size="lg"
      className="game-info-popup"
      show={gameInfo}
      onHide={() => handleGameInfo()}
      centered
    >
      {/* <Modal.Header closeButton>
          <div className="game-info-banner">
            <img src={selectedGame?.logo} alt="banner" />
            <div className="game-info-name">
              <h2>{selectedGame?.name||''}</h2>
            </div>
          </div>
        </Modal.Header> */}
      <Modal.Body>
        <div className="game-info-wrapper">
          <h6 className="mb-4">Update Account info</h6>
          {selectedGame?.id && (
            <div className="tabs-container">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-5"
              >
                <Tab eventKey="home" title="Username">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="input-label">Username</Form.Label>
                    <Form.Control
                      className="form-control mb-5 input-style"
                      type="email"
                      placeholder="Enter username"
                      defaultValue={selectedGame?.gameUsername}
                      onChange={handleChange}
                      name="gameUsername"
                      value={gameInfoData?.gameUsername}
                    />
                    <div className="d-flex align-items-center justify-content-evenly btn-footer">
                      <Button
                        variant="secondary btn-cancel p-3 px-md-5"
                        type="submit"
                        onClick={() => {
                          handleGameInfo();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary p-3 px-md-5"
                        type="submit"
                        onClick={() => {
                          updateGameInfo(selectedGame?._id, gameInfoData);
                        }}
                      >
                        Update
                      </Button>
                    </div>
                  </Form.Group>
                </Tab>
                <Tab eventKey="profile" title="Password">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="mb-5">
                      <Form.Label className="input-label">
                        New Password
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          className="form-control mb-3 input-style"
                          placeholder="Enter password"
                          type={pass ? "password" : "text"}
                          onChange={handleChange}
                          name="gamePassword"
                          // value={gameInfoData?.gamePassword}
                        />
                        <span
                          className="input-cpass-icon position-absolute"
                          onClick={handlePass}
                        >
                          <i
                            className={pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                      </div>
                      <Form.Label className="input-label">
                        Confirm Password
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          className="form-control mb-3 input-style"
                          placeholder="Enter password"
                          type={confirmPass ? "password" : "text"}
                          onChange={handleChange}
                          name="confirmPassword"
                          // value={gameInfoData?.gamePassword}
                        />
                        <span
                          className="input-cpass-icon position-absolute "
                          onClick={handleConfirmPass}
                        >
                          <i
                            className={
                              confirmPass ? "las la-eye" : "las la-eye-slash"
                            }
                          ></i>
                        </span>
                      </div>
                      {error && (
                        <p className="text-danger">Password not match</p>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-evenly btn-footer">
                      <Button
                        variant="secondary btn-cancel p-3 px-md-5"
                        type="submit"
                        onClick={() => {
                          handleGameInfo();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary p-3 px-md-5"
                        type="submit"
                        onClick={() => {
                          updatePassGameInfo(selectedGame?._id, gameInfoData);
                        }}
                      >
                        Update
                      </Button>
                    </div>
                  </Form.Group>
                </Tab>
              </Tabs>

              {/* <div className="game-info-grid">
                <div className="game-info-left">
                  <p>User name</p>
                </div>
                <div className="game-info-right">
                  <input
                    type="text"
                    defaultValue={selectedGame?.gameUsername}
                    onChange={handleChange}
                    name="gameUsername"
                    value={gameInfoData?.gameUsername}
                  />
               
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Password</p>
                </div>
                <div className="game-info-right">
               
                  <input
                    type="text"
                    defaultValue={selectedGame?.gamePassword}
                    onChange={handleChange}
                    name="gamePassword"
                    value={gameInfoData?.gamePassword}
                  />
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p></p>
                </div>
                <div className="game-info-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      updateGameInfo(selectedGame?._id, gameInfoData);
                    }}
                  >
                    Update
                  </button>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditGameInfoPopup;
