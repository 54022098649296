import { useState, useEffect } from "react";
import { Tabs, Tab, Button, Form, FormControl } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import { getAllContacts, updateStatusContacts } from "../../utils/Api";
import { getDate } from "../../utils/index.js";
// import PagePagination from "../pagination/Pagination";
import closeicon from "../../assets/images/question/close-icon.svg";
import user from "../../assets/images/chat/user.png";
import { useCallback } from "react";
import "./question.css";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import { adminInstance } from "../../config/axios";
import toast from "react-hot-toast";
import PagePaginationNew from "../pagination/PaginationNew";

const Question = () => {
  const {
    newContacts,
    setNewContacts,
    answeredContacts,
    setAnsweredContacts,
    spamContacts,
    setSpamContacts,
  } = useContext(UserContext);

  const [key, setKey] = useState("new");

  return (
    <AdminLayout>
      <div className='question-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Questions</h3>
            </div>
            <div className='admin-tab-section'>
              <Tabs
                onSelect={(k) => setKey(k)}
                activeKey={key}
                defaultActiveKey='new'
                id='uncontrolled-tab-example'>
                <Tab eventKey='new' title='New'>
                  <QuestionsTab
                    eventKey='new'
                    title='title'
                    contacts={newContacts}
                    setContacts={setNewContacts}
                    status='idle'
                    QuestionComponent={UserQuestion}
                    key={key}
                  />
                </Tab>
                <Tab eventKey='answered' title='Answered'>
                  <QuestionsTab
                    eventKey='answered'
                    title='Answered'
                    contacts={answeredContacts}
                    setContacts={setAnsweredContacts}
                    status='accept'
                    QuestionComponent={AnsweredRow}
                    key={key}
                  />
                </Tab>
                <Tab eventKey='spam' title='Spam'>
                  <QuestionsTab
                    eventKey='spam'
                    title='Spam'
                    contacts={spamContacts}
                    setContacts={setSpamContacts}
                    status='reject'
                    QuestionComponent={SpamQuestion}
                    key={key}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Question;

const QuestionsTab = ({
  contacts,
  setContacts,
  status,
  QuestionComponent,
  key,
  eventKey,
}) => {
  const initialObj = {
    first: 1,
    last: "",
    next: 2,
    prev: 1,
    activeindex: 1,
    skip: 0,
    limit: 10,
    status,
  };

  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const getAllContact = async (paginationObj, step) => {
    const result = await getAllContacts(setContacts, paginationObj);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      setPaginationStep(copyObj);
    }
  };

  useEffect(() => {
    if (key === eventKey) {
      getAllContact(initialObj, "first");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, eventKey]);

  const searchUser = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      await getAllContacts(setContacts, searchString);
    },
    [paginationStep, setContacts]
  );

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getAllContact(copyobj);
    setPaginationStep(copyobj);
  };


  return (
    <div className='new-question-tab'>
      <div className='row'>
        <div className='col-md-12'>
          {<SearchBoxComponent searchFunction={searchUser} />}
          <div className='new-question-tab-content'>
            {contacts.results &&
              contacts.results.length > 0 &&
              contacts.results.map((el) => (
                <QuestionComponent
                  key={el?.id ?? el?._id}
                  data={el}
                  getAllContact={getAllContact}
                  initialObj={initialObj}
                />
              ))}
          </div>

          {contacts?.totalPages > 1 && (
            <PagePaginationNew
              page={paginationStep?.activeindex}
              totalpages={contacts?.totalPages}
              handlePagination={handlePagenation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const UserQuestion = ({ data, getAllContact, initialObj }) => {
  const [reply, setReply] = useState(false);
  const [message, setMessage] = useState("");
  const handleReply = () => {
    setReply(!reply);
  };

  const handleStatusUpdate = async (contactId, emails, name) => {
    const data = { contactId, status: "reject", email: emails, name: name };
    const updateResponse = await updateStatusContacts(data);
    if (updateResponse.msg === "success") {
      toast.success("Message To Spam!");
      getAllContact(initialObj, "first");
      setReply(false);
      setMessage("");
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message) {
      setErrorMessage("Plese type your reply");
      return;
    }
    if (!data.id) {
      setErrorMessage("Failed to process request");
      return;
    }
    setErrorMessage("");
    try {
      const userResponse = await adminInstance().post("/contacts", {
        message: message,
        contactId: data.id,
        email: data.email,
        name: data.name,
      });
      if (userResponse.status === 200) {
        toast.success("Message Sent!");
        getAllContact(initialObj, "first");
        setReply(false);
        setMessage("");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to reply.");
    }
  };

  return (
    <>
      <div className={`${reply ? "answer-wrapper-show" : "question-wrapper"}`}>
        <div className='que-by-grid'>
          <div className='question-by'>
            <h6>{data?.name}</h6>
            <a href='{data?.email}'>{data?.email}</a>
          </div>
          {reply && (
            <span role='presentation' onClick={handleReply}>
              <img src={closeicon} alt='' />
            </span>
          )}
        </div>

        <div className='user-que-row'>
          <p>{data?.message}</p>
        </div>
        <div className='quetion-time'>
          <p>{getDate(data?.createdAt)}, 10:00</p>
          {!reply && (
            <div className='que-action-btn'>
              <Button
                className='spam-btn'
                onClick={() =>
                  handleStatusUpdate(data.id, data.email, data.name)
                }>
                Spam
              </Button>
              <Button onClick={handleReply} className='send-mail-btn'>
                Send Email
              </Button>
            </div>
          )}
        </div>
        {reply && (
          <div className='reply-box'>
            <Form onSubmit={handleSubmit} className='reply-box-form'>
              <FormControl
                as='textarea'
                type='text'
                placeholder='Write message to Mike'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              {errorMessage && <p className='text-danger'>{errorMessage}</p>}
              <Button type='submit'>Send</Button>
            </Form>
          </div>
        )}
      </div>
      {/* <div className={`${reply ? "answer-wrapper-show" : "answer-wrapper"}`}>
        <div className="que-by-grid">
          <div className="question-by">
            <h6>Mike Johnson</h6>
            <a href="mailto:mike.johnsoncasinoplayer@gmail.com">
              mike.johnsoncasinoplayer@gmail.com
            </a>
          </div>
          <span role="presentation" onClick={handleReply}>
            <img src={closeicon} alt="" />
          </span>
        </div>
        <div className="user-que-row">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </div>
        <div className="quetion-time">
          <p>02 September 2022, 10:00</p>
        </div>
        <div className="reply-box">
          <Form className="reply-box-form">
            <FormControl
              as="textarea"
              type="text"
              placeholder="Write message to Mike"
            />
            <Button type="submit">Send</Button>
          </Form>
        </div>
      </div> */}
    </>
  );
};

const AnsweredRow = ({ data, getAllContact, initialObj }) => {
  //console.log(data.adminReply.adminId);
  const [reply, setReply] = useState(false);
  const [message, setMessage] = useState("");
  const handleReply = () => {
    setReply(!reply);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message) {
      setErrorMessage("Plese type your reply");
      return;
    }
    if (!data.id) {
      setErrorMessage("Failed to process request");
      return;
    }
    setErrorMessage("");
    try {
      const userResponse = await adminInstance().post("/contacts", {
        message: message,
        contactId: data.id,
        email: data.email,
        name: data.name,
      });
      if (userResponse.status === 200) {
        toast.success("Message Sent!");
        getAllContact(initialObj, "first");
        setReply(false);
        setMessage("");
      }
      console.log({ userResponse });
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to reply.");
    }
  };

  return (
    // <div className="answered-wrapper">
    <div className={`${reply ? "answer-wrapper-show" : "question-wrapper"}`}>
      <div className='user-question-wrapper'>
        <div className='que-by-grid'>
          <div className='question-by'>
            <h6>{data?.name}</h6>
            <a href='mailto:{data?.email}'>{data?.email}</a>
          </div>
          {reply && (
            <span role='presentation' onClick={handleReply}>
              <img src={closeicon} alt='' />
            </span>
          )}
        </div>
        <div className='user-que-row'>
          <p>{data?.message}</p>
        </div>
        <div className='quetion-time'>
          <p>{getDate(data?.createdAt)}, 10:00</p>
        </div>
      </div>
      <div className='admin-answer-list-wrapper'>
        <div className='admin-answer-list'>
          {data.adminReply.map((element) => (
            <div
              className='admin-answer-wrapper'
              key={element?.id ?? element?._id}>
              <div className='ans-by-grid'>
                <div className='ans-by-img'>
                  <img src={element?.adminId?.avatar||user} alt='user' />
                </div>
                <div className='ans-by-heading'>
                  <h6>{element?.adminId["name"]}</h6>
                </div>
              </div>
              <div className='user-que-row'>
                {/* <p>{data.adminReply[data.adminReply.length - 1]?.message}</p> */}
                <p>{element?.message}</p>
              </div>
              <div className='quetion-time'>
                <p>
                  {/* {getDate(data.adminReply[data.adminReply.length - 1]?.createdAt)}{" "} */}
                  {getDate(element?.createdAt)}
                  {/* 10:00 */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {!reply && (
        <div className='another-mail-btn-wrapper'>
          <Button onClick={handleReply} className='another-mail-btn'>
            Send Another Email
          </Button>
        </div>
      )}

      {reply && (
        <div className='reply-box'>
          <Form onSubmit={handleSubmit} className='reply-box-form'>
            <FormControl
              as='textarea'
              type='text'
              placeholder='Write message to Mike'
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            {errorMessage && <p className='text-danger'>{errorMessage}</p>}
            <Button type='submit'>Send</Button>
          </Form>
        </div>
      )}
    </div>
  );
};

const SpamQuestion = ({ data, getAllContact, initialObj }) => {
  const [reply, setReply] = useState(false);
  const [message, setMessage] = useState("");
  const handleReply = () => {
    setReply(!reply);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message) {
      setErrorMessage("Plese type your reply");
      return;
    }
    if (!data.id) {
      setErrorMessage("Failed to process request");
      return;
    }
    setErrorMessage("");
    try {
      const userResponse = await adminInstance().post("/contacts", {
        message: message,
        contactId: data.id,
        email: data.email,
        name: data.name,
      });
      if (userResponse.status === 200) {
        toast.success("Message Sent!");
        getAllContact(initialObj, "first");
        setReply(false);
        setMessage("");
      }
      console.log({ userResponse });
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to reply.");
    }
  };

  return (
    <>
      <div className={`${reply ? "answer-wrapper-show" : "question-wrapper"}`}>
        <div className='que-by-grid'>
          <div className='question-by'>
            <h6>{data?.name}</h6>
            <a href='mailto:{data?.email}'>{data?.email}</a>
          </div>

          {reply ? (
            <span role='presentation' onClick={handleReply}>
              <img src={closeicon} alt='' />
            </span>
          ) : (
            <span className='mark-spam'>Marked as Spam</span>
          )}
        </div>

        <div className='user-que-row'>
          <p>{data?.message}</p>
        </div>
        <div className='quetion-time'>
          <p>{getDate(data?.createdAt)}, 10:00</p>
          <div className='que-action-btn'>
            {!reply && (
              <Button onClick={handleReply} className='send-mail-btn'>
                Send Email
              </Button>
            )}
          </div>
        </div>
        {reply && (
          <div className='reply-box'>
            <Form onSubmit={handleSubmit} className='reply-box-form'>
              <FormControl
                as='textarea'
                type='text'
                placeholder='Write message to Mike'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              {errorMessage && <p className='text-danger'>{errorMessage}</p>}
              <Button type='submit'>Send</Button>
            </Form>
          </div>
        )}
      </div>

      {/* <div className={`${reply ? "answer-wrapper-show" : "answer-wrapper"}`}>
        <div className="que-by-grid">
          <div className="question-by">
            <h6>Mike Johnson</h6>
            <a href="mailto:mike.johnsoncasinoplayer@gmail.com">
              mike.johnsoncasinoplayer@gmail.com
            </a>
          </div>
          <span role="presentation" onClick={handleReply}>
            <img src={closeicon} alt="" />
          </span>
        </div>
        <div className="user-que-row">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </div>
        <div className="quetion-time">
          <p>02 September 2022, 10:00</p>
        </div>
        <div className="reply-box">
          <Form className="reply-box-form">
            <FormControl
              as="textarea"
              type="text"
              placeholder="Write message to Mike"
            />
            <Button type="submit">Send</Button>
          </Form>
        </div>
      </div> */}
    </>
  );
};
