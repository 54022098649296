import { useEffect, useState } from 'react';

const useDebounce = (seach, cb, time, count) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let timeOut;
    if (count === 0) {
      return;
    }
    (() => {
      setLoading(false);
      timeOut = setTimeout(async () => {
        await cb(seach);
        setLoading(false);
      }, time);
    })();
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [cb, count, seach, time]);
  return [loading];
};

export default useDebounce;
