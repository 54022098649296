import { useEffect } from "react";
import AdminMenu from "../adminmenu/menu";
import AdminHeader from "../adminheader/header";
import "./layout.css";

const AdminLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={`main-wrapper`}>
      <div className="admin-content-view">
        <AdminHeader />

        <div className="admin-wrapper">
          <AdminMenu />

          <div className="admin-container">
            <div className="admin-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
