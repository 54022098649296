import { createContext } from 'react';

const UserContext = createContext({
  user: {},
  tokens: {},
  newContacts: [],
  answeredContacts: [],
  spamContacts: [],
});

export default UserContext;
