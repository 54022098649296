import { useState, useEffect } from "react";
import AdminLayout from "../layout/layout";
import { Button, Dropdown, Modal, Form, Spinner } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { offerSchema } from "../../validationSchema/authSchema";
import NoData from "../noData/index";
import offer1 from "../../assets/images/offer/offer1.png";
// import offer2 from "../../assets/images/offer/offer2.png";
import offer3 from "../../assets/images/offer/offer3.png";
// import offer4 from "../../assets/images/offer/offer4.png";
import editicon from "../../assets/images/offer/edit-icon.svg";
import deleteicon from "../../assets/images/offer/delete-icon.svg";
import smimage from "../../assets/images/offer/sm-image.svg";
import checkImage from "../../assets/images/check.svg";
import "./offer-edit.css";
import DeletePopup from "../delete-popup/delete-popup";
import {
  createOffer,
  fetchOffers,
  updateOffer,
  deleteOffer,
} from "../../utils/Api";
import PagePaginationNew from "../pagination/PaginationNew";

const OfferEditing = () => {
  const [newOffer, setnewOffer] = useState(false);
  const [newOfferStep, setnewOfferStep] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [uplaodedImage, setUplaodedImage] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeRatioType, setActiveRatioType] = useState("vertical");
  const [offerData, setOfferData] = useState([]);
  const [editValue, setEditValue] = useState(null);
  const initialPaginationValue = {
    limit: 0,
    page: 0,
    totalPages: 0,
    totalResults: 0,
  };
  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState("");
  useEffect(() => {
    const getGameRequestData = async () => {
      const res = await fetchOffers(paginationValue);
      if (res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data?.data;
        setOfferData(results || []);
        const pageData = { limit, page, totalPages, totalResults };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getGameRequestData();
  }, [paginationValue]);

  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  const handleOffer = () => {
    setnewOffer(!newOffer);
    setUplaodedImage([]);
  };

  const handleOfferStep = (checkSuccessUplaod, backToPrevious) => {
    if (uplaodedImage.length > 0) {
      setnewOffer(false);
      setnewOfferStep(true);
    } else {
      setErrorMsg("Please upload image.");
      setTimeout(() => {
        setErrorMsg("");
      }, 1500);
    }
    if (checkSuccessUplaod) {
      setUplaodedImage([]);
      setnewOfferStep(false);
      setnewOffer(false);
    }
    if (backToPrevious) {
      setnewOfferStep(false);
      setnewOffer(true);
    }
  };
  const handleDeletePopup = (data) => {
    setEditValue(data);
    setDeletePopup(!deletePopup);
  };
  const handleDelete = async (id) => {
    setDeleteLoading(true);
    const res = await deleteOffer(id);
    setDeleteLoading(false);
    if (res.status === 201) {
      getGameRequestData();
      setDeleteSuccess("Offer deleted.");
      setTimeout(() => {
        setDeletePopup(!deletePopup);
        setDeleteSuccess("");
      }, 1500);
    }
  };

  const handleImageChange = (e) => {
    setUplaodedImage([...e.target.files]);
  };
  const handleEdit = (data) => {
    setEditValue(data);
    setnewOfferStep(true);
  };

  const handleEditClose = () => {
    setnewOfferStep(false);
    setEditValue(null);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    setActiveRatioType(value);
  };

  const getGameRequestData = async () => {
    const res = await fetchOffers(paginationValue);
    if (res.status === 200) {
      const { results, limit, page, totalPages, totalResults } =
        res?.data?.data;
      setOfferData(results || []);
      const pageData = { limit, page, totalPages, totalResults };
      if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
        setPaginationValue(pageData);
      }
    }
  };

  return (
    <AdminLayout>
      <div className="offer-edit-page">
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="admin-section-title">
              <h3>Offer page</h3>
            </div>
            <div className="admin-tab-section">
              <div className="offer-tab">
                <div className="row">
                  <div className="col-md-12">
                    <Button className="create-offer-btn" onClick={handleOffer}>
                      Create a new offer
                    </Button>
                    <div className="offer-tab-content">
                      <div className="simple-offer-wrapper">
                        <div className="simple-offer-grid">
                          {offerData.length > 0 ? (
                            <>
                              {offerData.map((el) => (
                                <div className="normal-offer-box"  key={el?.id?? el?._id}>
                                  <img src={el?.offerImage || offer1} alt="" />
                                  <div className="inner-box">
                                    <h5>{el?.title || ""}</h5>
                                    <h6>A{el?.description || ""}</h6>
                                    <Button>Collect now</Button>
                                  </div>
                                  <div
                                    className="bg-color"
                                    style={{
                                      background: `linear-gradient(0deg, ${el?.backgroundColor} 59.38%, rgba(255, 133, 23, 0) 100%)`,
                                    }}
                                  ></div>
                                  <div className="edit-offer-menu">
                                    <Dropdown align="end">
                                      <Dropdown.Toggle>
                                        <MenuSVG />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() => handleEdit(el)}
                                        >
                                          Edit <img src={editicon} alt="icon" />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() => handleDeletePopup(el)}
                                        >
                                          Delete{" "}
                                          <img src={deleteicon} alt="icon" />
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <NoData />
                          )}
                        </div>
                        {paginationValue.totalPages > 1 && (
                          <PagePaginationNew
                            page={paginationValue?.page}
                            totalpages={paginationValue?.totalPages}
                            handlePagination={handlePagination}
                            handleOnChange={handleOnChange}
                          />
                        )}
                      </div>
                      {newOffer && (
                        <NormalOfferPopup
                          newOffer={newOffer}
                          handleOffer={handleOffer}
                          handleOfferStep={handleOfferStep}
                          uplaodedImage={uplaodedImage}
                          handleImageChange={handleImageChange}
                          errorMsg={errorMsg}
                          activeRatioType={activeRatioType}
                          setActiveRatioType={setActiveRatioType}
                          handleOnChange={handleOnChange}
                        />
                      )}

                      {newOfferStep && (
                        <NormalOfferStep2
                          newOfferStep={newOfferStep}
                          handleOfferStep={handleOfferStep}
                          uplaodedImage={uplaodedImage}
                          activeRatioType={activeRatioType}
                          editValue={editValue}
                          handleEditClose={handleEditClose}
                          getGameRequestData={getGameRequestData}
                          setActiveRatioType={setActiveRatioType}
                          handleOnChange={handleOnChange}
                        />
                      )}
                      {deletePopup && (
                        <DeletePopup
                          deletePopup={deletePopup}
                          handleDeletePopup={handleDeletePopup}
                          deleteHeadeing="Please confirm if you want to delete this offer"
                          deleteSubHeading="After you confirm, this offer will be deleted."
                          handleDelete={handleDelete}
                          data={editValue}
                          deleteLoading={deleteLoading}
                          deleteSuccess={deleteSuccess}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default OfferEditing;

const NormalOfferPopup = ({
  newOffer,
  handleOffer,
  handleOfferStep,
  handleImageChange,
  uplaodedImage,
  errorMsg,
  activeRatioType,
  handleOnChange,
}) => {
  return (
    <Modal
      size="lg"
      className="normal-offer-step1"
      show={newOffer}
      onHide={() => handleOffer()}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h5>Create a new Offer</h5>
        <div className="normal-offer-form-1">
          <Form>
            <div className="select-banner-area">
              <p>Chose banner ratio </p>
              <div className="select-banner-option">
                <Form.Group
                  className={`form-group ${
                    activeRatioType === "vertical" ? "active" : ""
                  }`}
                  htmlFor="vertical"
                >
                  <Form.Check
                    label="Vertical"
                    name="bannerRatio"
                    type="radio"
                    id="vertical"
                    value="vertical"
                    defaultChecked={
                      activeRatioType === "vertical" ? true : false
                    }
                    onChange={handleOnChange}
                  />
                </Form.Group>
                <Form.Group
                  className={`form-group ${
                    activeRatioType === "horizontal" ? "active" : ""
                  }`}
                  htmlFor="horizontal"
                >
                  <Form.Check
                    label="Horizontal"
                    name="bannerRatio"
                    type="radio"
                    id="horizontal"
                    value="horizontal"
                    defaultChecked={
                      activeRatioType === "horizontal" ? true : false
                    }
                    onChange={handleOnChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div
              className={`upload-image-area ${
                activeRatioType === "horizontal" ? "horizontal" : ""
              }`}
            >
              <p>Upload the image</p>
              <Form.Group className="form-group">
                <div className="custom-file">
                  <Form.Control
                    type="file"
                    id="bannerupload"
                    className="custom-file-input"
                    name="bannerupload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <Form.Label
                    htmlFor="bannerupload"
                    className="custom-file-label"
                  >
                    {uplaodedImage.length > 0 ? (
                      <div>
                        <img
                          src={window.URL.createObjectURL(uplaodedImage[0])}
                          alt="logo-img"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="upload-banner-content">
                          <h6>
                            Drag&Drop or <span> Upload</span> the banner.
                          </h6>
                          <p>
                            {activeRatioType === "horizontal" ? "704" : "262"}
                            x380 px
                          </p>
                        </div>
                        <p className="make-sue">
                          Make sure that 85 px from the bottom should be empty
                        </p>
                        <div className="safe-zone">
                          <span>Safe zone</span>
                          <p>Collect now</p>
                        </div>
                      </>
                    )}
                    {errorMsg && (
                      <h6 className="text-danger text-center">{errorMsg}</h6>
                    )}
                  </Form.Label>
                </div>
              </Form.Group>
            </div>
            <div className="special-offer-footer">
              <Button variant="secondary" onClick={handleOffer}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleOfferStep(false)}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const NormalOfferStep2 = ({
  newOfferStep,
  handleOfferStep,
  uplaodedImage,
  activeRatioType,
  editValue,
  handleEditClose,
  handleOnChange,
  getGameRequestData,
  setActiveRatioType,
}) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
    control,
  } = useForm({ resolver: yupResolver(offerSchema) });
  const [uplaodedImageCurrent, setUplaodedImageCurrent] = useState([]);
  const [bgColorHex, setBgColorHex] = useState(
    editValue?.backgroundColor ? editValue?.backgroundColor : "#7749f3"
  );
  const [laoding, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handleOfferSubmit = async (value) => {
    const formData = new FormData();
    let payload = { ...value };
    payload.backgroundColor = bgColorHex;
    payload.bannerRatio = activeRatioType;
    const offerImage = editValue?.offerImage
      ? [editValue?.offerImage]
      : uplaodedImageCurrent.length > 0
      ? uplaodedImageCurrent
      : uplaodedImage.length > 0
      ? uplaodedImage
      : [];
    if (offerImage.length !== 1) {
      setError("offerImg", { message: "Please uplaod offer image limit" });
      return;
    } else if (!editValue?.offerImage) {
      formData.append("offerImage", offerImage[0]);
      payload.newImage = true;
    } else {
      payload.newImage = false;
    }
    formData.append("formValues", JSON.stringify(payload));
    setLoading(true);
    let res;
    if (!editValue) {
      res = await createOffer(formData);
    } else {
      res = await updateOffer(editValue.id, formData);
    }
    setLoading(false);
    if (res.status === 201) {
      getGameRequestData();
      setSuccessMsg(editValue ? "Offer updated" : "Offer Created");
      setTimeout(() => {
        closePopup(true);
        setSuccessMsg("");
      }, 1500);
    }
  };
  const handleImageChange = (e) => {
    if (editValue) {
      editValue.offerImage = "";
    }
    setUplaodedImageCurrent([...e.target.files]);
  };

  const closePopup = (closePopup, backToPrevious) => {
    if (editValue) {
      handleEditClose();
      reset();
      setUplaodedImageCurrent([]);
    } else {
      handleOfferStep(closePopup, backToPrevious);
      reset();
      setUplaodedImageCurrent([]);
    }
  };
  const changeBGColor = (colorHex) => {
    setBgColorHex(colorHex);
  };
  return (
    <Modal
      size="xl"
      className="normal-offer-step2"
      show={newOfferStep}
      onHide={() => closePopup(false, true)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {editValue ? "Offer editing" : "Create Offer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleOfferSubmit)}>
          <div className="normal-offer-form-2">
            <div className="normal-offer-form-left">
              <div className="area-title">
                <h5>Editing</h5>
              </div>
              <div className="select-banner-area">
                <p>Chose banner ratio </p>
                <div className="select-banner-option">
                  <Form.Group
                    className={`form-group ${
                      activeRatioType === "vertical" ? "active" : ""
                    }`}
                    htmlFor="vertical"
                  >
                    <Form.Check
                      label="Vertical"
                      name="bannerRatio"
                      type="radio"
                      id="vertical"
                      value="vertical"
                      defaultChecked={
                        activeRatioType === "vertical" ? true : false
                      }
                      disabled={!editValue}
                      onChange={handleOnChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      activeRatioType === "horizontal" ? "active" : ""
                    }`}
                    htmlFor="horizontal"
                  >
                    <Form.Check
                      label="Horizontal"
                      name="bannerRatio"
                      type="radio"
                      id="horizontal"
                      value="horizontal"
                      defaultChecked={
                        activeRatioType === "horizontal" ? true : false
                      }
                      disabled={!editValue}
                      onChange={handleOnChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="form-group">
                <Form.Label>Header</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="25% bonus for all deposit above $1000"
                  name="title"
                  defaultValue={editValue ? editValue?.title : ""}
                  {...register("title")}
                />
                {errors?.title ? (
                  <p className="error-text">{errors?.title?.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  autoComplete="off"
                  placeholder="Applicable for any games"
                  name="description"
                  defaultValue={editValue ? editValue?.description : ""}
                  {...register("description")}
                />
                {errors?.description ? (
                  <p className="error-text">{errors?.description?.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <div className="edit-image-area">
                <p>
                  Upload the photo
                  <span className="edit-image-span">
                    ( 254*254px recommend)
                  </span>
                </p>
                <Form.Group className="form-group">
                  <div className="custom-file">
                    <Form.Control
                      type="file"
                      name="editbanner"
                      id="editbanner"
                      accept=".png, .jpg, .jpeg"
                      className="custom-file-input"
                      onChange={handleImageChange}
                    />
                    <Form.Label
                      htmlFor="editbanner"
                      className="custom-file-label"
                    >
                      <div className="uploaded-image-area">
                        <div className="uploaded-image">
                          {uplaodedImage.length > 0 ||
                          uplaodedImageCurrent.length > 0 ||
                          editValue?.offerImage ? (
                            <>
                              {uplaodedImage.length > 0 &&
                                uplaodedImageCurrent.length === 0 && (
                                  <img
                                    src={window.URL.createObjectURL(
                                      uplaodedImage[0]
                                    )}
                                    alt="logo-img"
                                  />
                                )}

                              {uplaodedImageCurrent.length > 0 && (
                                <img
                                  src={window.URL.createObjectURL(
                                    uplaodedImageCurrent[0]
                                  )}
                                  alt="logo-img"
                                />
                              )}
                              {editValue?.offerImage && (
                                <img
                                  src={editValue?.offerImage}
                                  alt="logo-img"
                                />
                              )}
                            </>
                          ) : (
                            <img src={offer3} alt="" />
                          )}
                        </div>
                        <span className="small-image">
                          <img src={smimage} alt="icon" />
                        </span>
                      </div>
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>

              <div className="bg-color-wrapper">
                <h6>Chose BG color</h6>
                <div className="bg-color-grid">
                  <div
                    className="bg-color-box"
                    onClick={() => changeBGColor("#45b928")}
                  >
                    {bgColorHex === "#45b928" && (
                      <span className="checkbox-span">
                        <img src={checkImage} alt="checkImag" />
                      </span>
                    )}

                    <div className="bg-color-head green-bg"></div>
                    <div className="bg-color-footer">Green</div>
                  </div>
                  <div
                    className="bg-color-box"
                    onClick={() => changeBGColor("#7749f3")}
                  >
                    {bgColorHex === "#7749f3" && (
                      <span className="checkbox-span">
                        <img src={checkImage} alt="checkImage" />
                      </span>
                    )}
                    <div className="bg-color-head purple-bg"></div>
                    <div className="bg-color-footer">Purple</div>
                  </div>

                  <div
                    className="bg-color-box"
                    onClick={() => changeBGColor("#ff8517")}
                  >
                    {bgColorHex === "#ff8517" && (
                      <span className="checkbox-span">
                        <img src={checkImage} alt="checkImag" />
                      </span>
                    )}
                    <div className="bg-color-head  orange-bg"></div>
                    <div className="bg-color-footer">Orange</div>
                  </div>
                  <div
                    className="bg-color-box "
                    onClick={() => changeBGColor("#4878f3")}
                  >
                    {bgColorHex === "#4878f3" && (
                      <span className="checkbox-span">
                        <img src={checkImage} alt="checkImag" />
                      </span>
                    )}
                    <div className="bg-color-head blue-bg"></div>
                    <div className="bg-color-footer">Blue</div>
                  </div>
                  <div
                    className="bg-color-box "
                    onClick={() => changeBGColor("#b92381")}
                  >
                    {bgColorHex === "#b92381" && (
                      <span className="checkbox-span">
                        <img src={checkImage} alt="checkImag" />
                      </span>
                    )}
                    <div className="bg-color-head pink-bg"></div>
                    <div className="bg-color-footer">Pink</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`normal-offer-form-right ${
                activeRatioType === "horizontal" ? "horizontal-image" : ""
              }`}
            >
              <div className="area-title">
                <h5>Preview</h5>
              </div>
              <div className="normal-offer-box" id="dynamicImg">
                {uplaodedImage.length > 0 ||
                uplaodedImageCurrent.length > 0 ||
                editValue?.offerImage ? (
                  <>
                    {uplaodedImage.length > 0 &&
                      uplaodedImageCurrent.length === 0 && (
                        <img
                          src={window.URL.createObjectURL(uplaodedImage[0])}
                          alt="logo-img"
                        />
                      )}

                    {uplaodedImageCurrent.length > 0 && (
                      <img
                        src={window.URL.createObjectURL(
                          uplaodedImageCurrent[0]
                        )}
                        alt="logo-img"
                      />
                    )}
                    {uplaodedImageCurrent.length <= 0 &&
                      editValue?.offerImage && (
                        <img src={editValue?.offerImage} alt="logo-img" />
                      )}
                  </>
                ) : (
                  <img src={offer3} alt="" />
                )}
                <ImageTextDislay control={control} editValue={editValue} />
                <div
                  className="bg-color"
                  style={{
                    background: `linear-gradient(0deg, ${bgColorHex} ${
                      activeRatioType === "horizontal" ? "68.85%" : "59.38%"
                    }, rgba(255, 133, 23, 0) 100%)`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {successMsg && (
            <p className="text-success text-center">{successMsg}</p>
          )}
          <div className="special-offer-footer">
            <Button variant="secondary" onClick={() => closePopup(false, true)}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {!laoding ? " Publish" : <Spinner animation="border" />}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const MenuSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="10" r="2" fill="#0E0D27" />
      <circle cx="10" cy="10" r="2" fill="#0E0D27" />
      <circle cx="17" cy="10" r="2" fill="#0E0D27" />
    </svg>
  );
};

export const MenuSVGBlue = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="10" r="2" fill="#2E8BE6" />
      <circle cx="10" cy="10" r="2" fill="#2E8BE6" />
      <circle cx="17" cy="10" r="2" fill="#2E8BE6" />
    </svg>
  );
};

const ImageTextDislay = ({ control, editValue }) => {
  const title = useWatch({
    control,
    name: "title",
    defaultValue: editValue ? editValue?.title : "Enter title",
  });

  const description = useWatch({
    control,
    name: "description",
    defaultValue: editValue ? editValue?.description : "Enter description",
  });

  return (
    <div className="inner-box">
      <h5>{title}</h5>
      <h6>{description}</h6>
      <Button>Collect now</Button>
    </div>
  );
};
