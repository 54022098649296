import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import UserContext from "./context/UserContext";
import { getNotifications, getUser, idleRequestCounts } from "./utils/Api";
import logo from "./assets/images/header/logo3d.png";
import "react-phone-input-2/lib/bootstrap.css";
import "./assets/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login/login";
import Forget from "./components/forget/forget";
import ResetPassword from "./components/forget/reset-password";
import PageNotFound from "./components/404_page";
import Client from "./components/clients";
import PlayerProfile from "./components/playerProfile";
import KYCPage from "./components/KYC";
import Contactus from "./components/contact-us";
import GameRequest from "./components/game-request";
import GameRecharge from "./components/game-recharge";
import GameRedeem from "./components/game-redeem";
import Withdraw from "./components/withdraw";
import Deposit from "./components/deposit";
import SupportChat from "./components/supportchat/chat";
import Question from "./components/question";
import DistributorRequest from "./components/distributor";
import OfferEditing from "./components/offer-editing";
import UserRoute from "./components/userRoutes/userRoutes";
import Setting from "./components/settings/setting";
import MyGames from "./components/my-game";
import "./responsive.css";
import ImageSetting from "./components/imageSettings";
import SubAdmins from "./components/subAdmin";

function App() {
  const [user, setUser] = useState(null);
  // FOR QUESTION PAGE
  const [newContacts, setNewContacts] = useState([]);
  const [answeredContacts, setAnsweredContacts] = useState([]);
  const [spamContacts, setSpamContacts] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tokens, setTokens] = useState(
    localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : {}
  );
  const [resetPassEmailValue, setResetPassEmailValue] = useState("");

  useEffect(() => {
    const getUserCallback = (data) => {
      setIsLoading(false);
      if (data.success) {
        setUser(data.user);
        <Navigate to="/players" />;
      } else {
        <Navigate to="/" />;
      }
    };
    getUser(getUserCallback);
  }, []);

  
  useEffect(() => {
    const fetchNotification = async () => {
      const res = await getNotifications();
      if (res.status === 200) {
        let notificationsArr = res?.data?.data || [];
        const filterNotifications = notificationsArr.filter(
          (el) => el.contentId !== null
        );
        setNotifications(filterNotifications);
      }
    }
    if(user){
      fetchNotification();
    }
  }, [user]);


  const [idleReqCounts,setIdleReqCounts] = useState({
    "withdraw": 0,
    "recharge": 0,
    "deposit": 0,
    "redeem": 0,
    "gameRequest": 0,
    "kycRequest":0
})

  const getIdleReqCount = async ()=>{
    const res = await idleRequestCounts();
    const {status,data} = res;
    if(status ===200){
      let {result} = data;
      let obj = {} 
      result[0].value.forEach((el)=> obj[el._id] = el?.counts)
      obj['gameRequest'] = result[1]?.value|| 0
      obj['kycRequest'] = result[2]?.value|| 0
      setIdleReqCounts(obj)
    }
  }
  

  useEffect(() => {
    getIdleReqCount();
  }, [user?.name]);


  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        tokens,
        setTokens,
        resetPassEmailValue,
        setResetPassEmailValue,
        newContacts,
        setNewContacts,
        answeredContacts,
        setAnsweredContacts,
        spamContacts,
        setSpamContacts,
        notifications, 
        setNotifications,
        idleReqCounts
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App">
          <Toaster />
          <Router>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/forget-password" element={<Forget />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/" element={<UserRoute />}>
                <Route exact path="/players" element={<Client />} />
                <Route exact path="/subAdmin" element={<SubAdmins />} />
                <Route exact path="/kyc-request" element={<KYCPage />} />
                <Route exact path="/contact-us" element={<Contactus />} />
                <Route exact path="/game-request" element={<GameRequest />} />
                <Route exact path="/game-recharge" element={<GameRecharge />} />
                <Route exact path="/game-redeem" element={<GameRedeem />} />
                <Route exact path="/withdraw" element={<Withdraw />} />
                <Route exact path="/deposit" element={<Deposit />} />
                <Route exact path="/live-chat" element={<SupportChat />} />
                <Route exact path="/question" element={<Question />} />
                <Route exact path="/players/playerProfile" element={<PlayerProfile />} />
                <Route exact path="/image-settings" element={<ImageSetting />} />
                <Route
                  exact
                  path="/distributor-request"
                  element={<DistributorRequest />}
                />
                <Route exact path="/offer-edit" element={<OfferEditing />} />
                <Route exact path="/settings" element={<Setting />} />
                <Route exact path="/games" element={<MyGames />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </div>
      )}
    </UserContext.Provider>
  );
}

export default App;

const Loader = () => {
  return (
    <div className="loader">
      <div className="crash-logo">
        <div className="animated-logo">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};
