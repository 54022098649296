import "./pageheader.css";
import coinicon from "../../assets/images/header/coin-icon.svg";
import {
  changeCurrencyFormat,
  getDate,
  getTime,
  multiplyValue,
} from "../../utils";

const UserProfileHeader = ({
  pageTitle,
  totalData,
  userImage,
  registeredDate,
  lastLogin,
}) => {
  return (
    <div className="page-header-section">
      <div className="container">
        <div className="page-header">
          <div className="page-header-title">
            <div className="user-data player-profile-user-data">
              <div className="user-data">
                <img src={userImage} alt="user" height="200px" width="200px" />
                <h2>{pageTitle}</h2>
              </div>
              <div className="registered-date">
                <h6>Date Registered</h6>
                <p>{getDate(registeredDate)}</p>
              </div>
            </div>
            <div className="sub-headings">
              <div className="wallet-info-wrapper">
                <p>Total available balance in coins</p>
                <div className="wallet-info">
                  <div className="total-coins">
                    <p>
                      <img src={coinicon} alt="coin" />{" "}
                      {changeCurrencyFormat(totalData || 0)}
                    </p>
                  </div>
                  <div className="coins-value-box">
                    <p>
                      USD:{" "}
                      <sapn>
                        $ {changeCurrencyFormat(multiplyValue(totalData, 1))}
                      </sapn>
                    </p>
                  </div>
                  <div className="coins-value-box">
                    <p>
                      Bitcoin: <sapn>{multiplyValue(totalData, 0.000047)}</sapn>
                    </p>
                  </div>
                </div>
              </div>
              <div className="last-login-wrapper">
                <p style={{ color: "#fff" }}>Last Login</p>
                <div className="coins-value-box last-login">
                  <p style={{ color: "rgb(255 255 255 / 50%)" }}>
                    {getDate(lastLogin)} {getTime(lastLogin)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileHeader;
