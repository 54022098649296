import { useState, useEffect } from "react";
//import { useForm } from "react-hook-form";
import AdminLayout from "../layout/layout";
import PageHeader from "../pageheader/pageheader";
import SearchBoxComponent from "../search/searchBoxComponent";
import { Table } from "react-bootstrap";
import { getAllContacts } from "../../utils/Api";
import { getDate } from "../../utils/index.js";
//import rightArrow from "../../assets/images/table/right-arrow.svg";
//import user from "../../assets/images/table/user2.png";
//import downArrow from "../../assets/images/table/down-arrow.svg";
//import coinicon from "../../assets/images/header/coin-icon.svg";
import "./contact-us.css";
import PagePagination from "../pagination/Pagination";
import { useCallback } from "react";

const Contactus = () => {
  const [liveUsers, setLiveUsers] = useState([]);
  const initialObj = {
    first: 1,
    last: "",
    next: 2,
    prev: 1,
    activeindex: 1,
    skip: 0,
    limit: 10,
  };
  const [paginationStep, setPaginationStep] = useState(initialObj);

  //console.log('SET LIVE URSES....',liveUsers);
  const getAllContact = async (paginationObj, step) => {
    const result = await getAllContacts(setLiveUsers, paginationObj);
    console.log("Sandeep-------", result);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      setPaginationStep(copyObj);
    }
  };

  useEffect(() => {
    getAllContact(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getAllContact(copyobj);
    setPaginationStep(copyobj);
  };

  const searchUser = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      await getAllContacts(setLiveUsers, searchString);
    },
    [paginationStep]
  );

  return (
    <AdminLayout>
      <div className="client-page">
        <PageHeader pageTitle="Contacts Us" />
        <div className="admin-content-wrapper">
          {<SearchBoxComponent searchFunction={searchUser} />}
          <div className="admin-table">
            <div className="row">
              <div className="col-md-12">
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Message</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {liveUsers.results &&
                      liveUsers.results.length > 0 &&
                      liveUsers.results.map((element) => (
                        <tr key={element?.id?? element?._id}>
                          <td>{element?.name}</td>
                          <td>{element?.email}</td>
                          <td>{element?.status}</td>
                          <td>{element?.message}</td>
                          <td>{getDate(element?.createdAt)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <PagePagination
                  totalcount={liveUsers?.totalResults}
                  totalpages={liveUsers?.totalPages}
                  handlePagenation={handlePagenation}
                  paginationStep={paginationStep}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Contactus;
