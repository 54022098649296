import { useEffect } from "react";
import { useState } from "react";
import { Tabs, Tab, Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { getImageSetting, uploadImageSetting } from "../../utils/Api";
import AdminLayout from "../layout/layout";
import "./imageSettings.css";

const ImageSetting = () => {
  const [uplaodedImage, setUploadImage] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewtab, setViewTab] = useState("homePageImg");
  const [previousImage, setPreviousImages] = useState(null);
  const [inputLoader,setInputLoader]= useState(false)
  const handleTabChange = (tab) => {
    setViewTab(tab);
    setUploadImage([])
    setInputLoader(true);
    setTimeout(()=>{
      setInputLoader(false)
    },500)
  };
  const handleImageChange = (e) => {
    setUploadImage(e.target.files);
  };

  const handleSave = async () => {
    if (uplaodedImage?.length !== 1) {
      return setError("Please upload image.");
    }
    const formData = new FormData();
    formData.append("imageData", uplaodedImage[0]);
    formData.append("fileName", viewtab);
    setLoading(true);
    const response = await uploadImageSetting(formData);
    setLoading(false);
    if (response.status === 201) {
      toast.success("Image uploaded.", {
        duration: 2000,
      });
      getImages();
    }
  };
  const getImages = async () => {
    const res = await getImageSetting();
    if (res.status === 200) {
      setPreviousImages(res.data.result);
    }
  };

  useEffect(() => {
    setTimeout(()=>{
      setInputLoader(false)
    },500)
    getImages();
    setInputLoader(true);

  }, []);

  return (
    <AdminLayout>
      <div className='deposit-page'>
        {/* <UserProfileHeader
          pageTitle={userDetails?.nickName}
          totalData={userDetails?.coin}
          userImage={userDetails?.avatar}
          registeredDate={userDetails?.createdAt}
          lastLogin={userDetails?.lastLogin}
        /> */}
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-tab-section'>
              <Tabs
                defaultActiveKey='homePageImg'
                id='uncontrolled-tab-example'
                onSelect={handleTabChange}>
                <Tab eventKey='homePageImg' title='Home'>
                  <div className='admin-all-deposit'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='upload-image-area img-setting-ratio'>
                          <ImageInput
                            handleImageChange={handleImageChange}
                            uplaodedImage={uplaodedImage}
                            error={error}
                            loading={loading}
                            handleSave={handleSave}
                            previousImage={previousImage}
                            viewtab={viewtab}
                            inputLoader={inputLoader}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='gamePageImg' title='Games'>
                  <div className='admin-cash-deposit'>
                    <div className='row'>
                      <div className='upload-image-area img-setting-ratio'>
                        <ImageInput
                          handleImageChange={handleImageChange}
                          uplaodedImage={uplaodedImage}
                          error={error}
                          loading={loading}
                          handleSave={handleSave}
                          previousImage={previousImage}
                          viewtab={viewtab}
                          inputLoader={inputLoader}
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='offerPageImg' title='Offer'>
                  <div className='redeem-record'>
                    <div className='row'>
                      <div className='upload-image-area img-setting-ratio'>
                        <ImageInput
                          handleImageChange={handleImageChange}
                          uplaodedImage={uplaodedImage}
                          error={error}
                          loading={loading}
                          handleSave={handleSave}
                          previousImage={previousImage}
                          viewtab={viewtab}
                          inputLoader={inputLoader}
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='aboutPageImg' title='About'>
                  <div className='recharge-record'>
                    <div className='row'>
                      <div className='upload-image-area img-setting-ratio'>
                        <ImageInput
                          handleImageChange={handleImageChange}
                          uplaodedImage={uplaodedImage}
                          error={error}
                          loading={loading}
                          handleSave={handleSave}
                          previousImage={previousImage}
                          viewtab={viewtab}
                          inputLoader={inputLoader}
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ImageSetting;

const ImageInput = ({
  handleImageChange,
  uplaodedImage,
  error,
  loading,
  handleSave,
  previousImage,
  viewtab,
  inputLoader
}) => (
  <>
    <p style={{color:'#fff'}}>Upload the image</p>
    <Form.Group className='form-group'>
      <div className='custom-file'>
        <Form.Control
          type='file'
          id='bannerupload'
          className='custom-file-input'
          name='bannerupload'
          accept='.png, .jpg, .jpeg'
          onChange={handleImageChange}
        />
        <Form.Label htmlFor='bannerupload' className='custom-file-label'>
          { (previousImage && previousImage[viewtab])  || uplaodedImage.length > 0 ? (
            <div>
              <img
                src={uplaodedImage.length>0 ? window.URL.createObjectURL(uplaodedImage[0]) : previousImage[viewtab]  }
                alt='logo-img'
              />
            </div>
          ) : (
            <>
              <div className='upload-banner-content'>
                <h6>
                  <span> Upload</span> the Image.
                </h6>
                <p>1920px*850px</p>
              </div>
            </>
          )}
          {error && <h6 className='text-danger text-center'>{error}</h6>}
        </Form.Label>
      </div>
    </Form.Group>
    <Button
      className='popup-red'
      type='submit'
      style={{ float: "right" }}
      onClick={!loading ? handleSave : () => {}}>
      {!loading ? "Save/Update" : <Spinner animation='border' />}
    </Button>

  </>
);
