import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Navbar, Nav } from "react-bootstrap";
import usericon from "../../assets/images/header/user1.png";
// import coinicon from "../../assets/images/header/coin-icon.svg";
import logo from "../../assets/images/header/logo3d.png";
import "./header.css";
import AdminMenu from "../adminmenu/menu";
import { socket } from "../../config/socket";
import NotificationIcon from "./NotificationIcon";
import UserContext from "../../context/UserContext";
// import { changeCurrencyFormat } from "../../utils";
import Notification from "../Notification/index";

const AdminHeader = () => {
  const { user, notifications, setNotifications } = useContext(UserContext);
  const [showNotification, setShowNotification] = useState(false);
  const [showBellNotify, setBellNotify] = useState(false);
  const handleNotification = () => {
    setShowNotification(!showNotification);
  };

  useEffect(() => {
    socket.on("newNotificationAdmin", (data) => {
      try {
        const notificationsCopy = [...notifications];
        notificationsCopy.unshift(data.notification);
        setNotifications(notificationsCopy);
      } catch (error) {
        console.log(error);
      }
    });
  }, [notifications, setNotifications]);

  useEffect(() => {
    const findIsReadExist = notifications.findIndex((el) => !el.isRead);
    if (findIsReadExist > -1) {
      setBellNotify(true);
    }
  }, [notifications]);

  return (
    <>
      <div className='admin-header'>
        <Link to='/players' className='brand-name'>
          <img src={logo} alt='logo' /> <span>King of Fish Games</span>
        </Link>
        <div className='user-loggedin'>
          <NotificationIcon
            handleNotification={handleNotification}
            showBellNotify={showBellNotify}
          />

          {/* <span className="wallet-section">
            <img src={coinicon} alt="logout" /> {changeCurrencyFormat(user?.coin)}
          </span> */}
          <div className='user-pic'>
            <Dropdown>
              <Dropdown.Toggle id='dropdown-basic'>
                <img src={user?.avatar || usericon} alt='user' />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey='logout'>Logout</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Visit profile</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className='header-menu'>
          <Navbar expand='lg'>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav>
                <AdminMenu />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {showNotification && (
        <Notification
          showNotification={showNotification}
          handleNotification={handleNotification}
        />
      )}
    </>
  );
};

export default AdminHeader;
