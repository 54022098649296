import { useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Offcanvas } from "react-bootstrap";
import coinicon from "../../assets/images/header/coin-icon.svg";
import gameicone from "../../assets/images/table/game.png";
import cashicon from "../../assets/images/header/cash-app.svg";
import bitcoin from "../../assets/images/header/bitcoin.svg";
import { updateNotification } from "../../utils/Api";
import NoData from "../noData/index";
import UserContext from "../../context/UserContext";
import {
  capitalizeFirstLetter,
  getDate,
  getLinkRoute,
  getTime,
} from "../../utils/index";

const Notification = ({ showNotification, handleNotification }) => {
  const { notifications, setNotifications } = useContext(UserContext);
  const handleNotificationCheck = (id, i) => {
    markAsRead([id], [i]);
  };
  
  const markAllAsRead = () => {
    const notificationIds = [];
    const indexArr = []
    notifications.forEach((el,i) => {
      if (!el.isRead) {
        indexArr.push(i)
        return notificationIds.push(el.id);
      }
    });
    markAsRead(notificationIds,indexArr);
  };
  const markAsRead = async (updateNotificationId, indexArr) => {
    const res = await updateNotification(updateNotificationId);
    if (res.status === 200) {
      const copyNotifications = JSON.parse(JSON.stringify(notifications));
      indexArr.forEach((el)=> copyNotifications[el].isRead = true )
      setNotifications(copyNotifications);
      handleNotification()
    }
  };
  return (
    <Offcanvas
      show={showNotification}
      onHide={handleNotification}
      placement='end'
      className='notification-wrapper'
      backdrop={true}
      keyboard={true}>
      <div className=''>
        <div className='notification-header'>
          <h5>Notifications</h5>
          {notifications.length !== 0 && (
            <>
              {" "}
              <span onClick={() => markAllAsRead()}>MARK ALL AS READ</span>
            </>
          )}
        </div>

        <div className='notification-list'>
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification, index) => (
                <>
                  {notification.typeRef === "Transaction" && (
                    <>
                      <TransactionNotificationCard
                        notification={notification}
                        index={index}
                        key={notification?._id || notification?.id}
                        handleNotificationCheck={handleNotificationCheck}
                        
                      />
                    </>
                  )}
                  {notification.typeRef === "GameRequest" && (
                    <>
                      <GameRequestNotificationCard
                        notification={notification}
                        index={index}
                        key={notification?._id || notification?.id}
                        handleNotificationCheck={handleNotificationCheck}
                      />
                    </>
                  )}
                  {notification.typeRef === "GameDistribution" && (
                    <>
                      <GameDistributionNotificationCard
                        notification={notification}
                        index={index}
                        key={notification?._id || notification?.id}
                        handleNotificationCheck={handleNotificationCheck}
                      />
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <div className='notfication-card'>
              <NoData heading='No Notifications' />
            </div>
          )}
        </div>
      </div>
    </Offcanvas>
  );
};
export default Notification;

const TransactionNotificationCard = ({
  notification,
  index,
  key,
  handleNotificationCheck,
}) => (
  <>
    <div className='notfication-card' key={key}>
      <div className='notfication-card-header'>
        <h5>
          {capitalizeFirstLetter(notification?.contentId?.transaction || "")}{" "}
          request
        </h5>
        <Form.Check
          type='checkbox'
          checked={notification?.isRead ? true : false}
          disabled
        />
      </div>
      <div className='notfication-card-body'>
        <div className='transaction-body'>
          <div className='transaction-body-grid'>
            <div className='transaction-via'>
              <img
                src={
                  notification?.contentId?.payment === "cashApp"
                    ? cashicon
                    : bitcoin
                }
                alt='logo'
              />
            </div>
            <div className='transaction-type'>
              <p>{notification?.contentId?.transaction || ""}:</p>
              <h6>${notification?.contentId?.amountInUsd || 0}</h6>
            </div>
            <div className='transaction-amount'>
              <p>Coins:</p>
              <h6>
                <img src={coinicon} alt='' /> {notification?.contentId?.coin}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className='notfication-card-footer'>
        <h6>
          {getDate(notification?.contentId?.updatedAt)},{" "}
          {getTime(notification?.contentId?.updatedAt)}{" "}
        </h6>
        <div onClick={() => handleNotificationCheck(notification.id, index)}>
          <Link to={`/${getLinkRoute(notification)}`}>view details</Link>
        </div>
      </div>
    </div>
  </>
);

const GameRequestNotificationCard = ({
  notification,
  index,
  key,
  handleNotificationCheck,
}) => (
  <>
    <div className='notfication-card' key={key}>
      <div className='notfication-card-header'>
        <h5>Game request</h5>
        <Form.Check
          type='checkbox'
          checked={notification?.isRead ? true : false}
        />
      </div>
      <div className='notfication-card-body'>
        <div className='game-request-body'>
          <div className='notification-game-grid'>
            <div className='notification-game-image'>
              <img
                src={notification?.contentId?.gameId?.logo || gameicone}
                alt='game'
              />
            </div>
            <div className='notification-game-name'>
              <h6>{notification?.contentId?.gameId?.name || ""}</h6>
              <p>{notification?.contentId?.gameId?.playCount || 0} Games</p>
            </div>
          </div>
        </div>
      </div>
      <div className='notfication-card-footer'>
        <h6>
          {getDate(notification?.contentId?.updatedAt)},{" "}
          {getTime(notification?.contentId?.updatedAt)}
        </h6>
        <div onClick={() => handleNotificationCheck(notification.id, index)}>
          <Link to={`/${getLinkRoute(notification)}`}>view details</Link>
        </div>
      </div>
    </div>
  </>
);

const GameDistributionNotificationCard = ({
  notification,
  index,
  key,
  handleNotificationCheck,
}) => {
  return (
  <div className='notfication-card' key={key}>
    <div className='notfication-card-header'>
      <h5>Became a destributor request</h5>
      <Form.Check type='checkbox'      
       checked={notification?.isRead ? true : false}/>
    </div>
    <div className='notfication-card-body'>
      <div className='distributor-request-body'>
        <div className='distributor-request-box'>
          <p>name</p>
          <h6>{notification?.userId?.name}</h6>
        </div>
        <div className='distributor-request-box'>
          <p>Company name</p>
          <h6>{notification?.contentId?.companyName}</h6>
        </div>
        <div className='distributor-request-box'>
          <p>Games</p>
          <h6>{notification?.contentId?.numberOfGames}</h6>
        </div>
      </div>
    </div>
    <div className='notfication-card-footer' onClick={() => handleNotificationCheck(notification?.id, index)}>
      <h6>{getDate(notification?.contentId?.createdAt)},{" "}
          {getTime(notification?.contentId?.updatedAt)}</h6>
      <Link to={`/${getLinkRoute(notification)}`}>view details</Link>
    </div>
  </div>
)};
