import { Pagination } from "react-bootstrap";
import "./pagination.css";

const PagePaginationNew = ({totalpages,page,handlePagination}) => {
  return (
    <div className="pagination-sec">
      <Pagination>
        {page > 1 && <>
          <Pagination.First onClick={()=>handlePagination(1)}/>
        <Pagination.Prev onClick={()=>handlePagination(page-1)}/>
        </>}
        {totalpages > 1 && page >1 && <Pagination.Item onClick={()=>handlePagination(page-1)}>{page-1}</Pagination.Item>}
        <Pagination.Item active>{page}</Pagination.Item>
        {page < totalpages &&  (page+1 !== totalpages) && <><Pagination.Item onClick={()=>handlePagination(page+1)}>{page+1}</Pagination.Item></>}
        {totalpages !== page && <>
        <Pagination.Ellipsis />
        <Pagination.Item onClick={()=>handlePagination(totalpages)}>{totalpages}</Pagination.Item>
        <Pagination.Next onClick={()=>handlePagination(page+1)}/>
        <Pagination.Last onClick={()=>handlePagination(totalpages)}/>
        </>}
      </Pagination>
    </div>
  );
};

export default PagePaginationNew;
