import { useState, useEffect } from "react";
import { Tabs, Tab, Button, Form, FormControl, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import { getAllDistributor, updateStatusDistributor } from "../../utils/Api";
import { getDate } from "../../utils/index.js";
//import UserContext from "../../context/UserContext";
//import { adminInstance } from "../../config/axios";
import { useCallback } from "react";
// import PagePagination from "../pagination/Pagination";
import PagePaginationNew from "../pagination/PaginationNew";
import user from "../../assets/images/chat/user.png";
import "./distributor.css";
import { adminInstance } from "../../config/axios";
import NoData from "../noData";

const DistributorRequest = () => {
  const [liveDistributor, setLiveDistributors] = useState([]);
  const initialObj = {
    first: 1,
    last: "",
    next: 2,
    prev: 1,
    activeindex: 1,
    skip: 0,
    limit: 10,
    status: "idle",
  };
  const [paginationStep, setPaginationStep] = useState(initialObj);
  const [viewStatus, setviewStatus] = useState("idle");

  const getDistributor = async (paginationObj, step) => {
    const result = await getAllDistributor(setLiveDistributors, paginationObj);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      copyObj.status = viewStatus;
      setPaginationStep(copyObj);
    }
  };

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getDistributor(copyobj);
    setPaginationStep(copyobj);
  };

  const searchDistributor = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      searchString.status = viewStatus;
      await getAllDistributor(setLiveDistributors, searchString);
    },
    [paginationStep, viewStatus]
  );

  const handleDistributorNewTabs = (event) => {
    if (event === "new" && viewStatus !== "idle") {
      setviewStatus("idle");
      getDistributor({ ...initialObj });
      setPaginationStep({ ...paginationStep, status: "idle" }, "first");
    } else if (viewStatus !== "accept") {
      setviewStatus("accept");
      getDistributor({ ...initialObj, status: "accept" }, "first");
      setPaginationStep({ ...paginationStep, status: "accept" });
    }
  };

  useEffect(() => {
    getDistributor(
      {
        first: 1,
        last: "",
        next: 2,
        prev: 1,
        activeindex: 1,
        skip: 0,
        limit: 10,
        status: "idle",
      },
      "first"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      <div className='distributor-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Distributor Request</h3>
            </div>
            <div className='admin-tab-section'>
              <Tabs
                defaultActiveKey='new'
                id='uncontrolled-tab-example'
                onSelect={handleDistributorNewTabs}>
                <Tab eventKey='new' title='New'>
                  <div className='new-distributor-tab'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent
                          searchFunction={searchDistributor}
                        />
                        <div className='new-distributor-tab-content'>
                          {liveDistributor.results &&
                          liveDistributor.results.length > 0 ? (
                            <>
                              {liveDistributor.results.map((element, index) => (
                                <RequestDistributor
                                  getObjectData={element}
                                  initialObject={initialObj}
                                  setLiveDistributors={setLiveDistributors}
                                  paginationStep={paginationStep}
                                  index={index}
                                  liveDistributor={liveDistributor}
                                  key={element?.id ?? element?._id}
                                />
                              ))}
                            </>
                          ) : (
                            <NoData heading='No Distributor Data'/>
                          )}
                        </div>
                        {liveDistributor?.totalPages > 1 && (
                          <PagePaginationNew
                            page={liveDistributor?.page}
                            totalpages={liveDistributor?.totalPages}
                            handlePagination={handlePagenation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='completed' title='Completed'>
                  <div className='complete-distributor-tab'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SearchBoxComponent
                          searchFunction={searchDistributor}
                        />
                        <div className='admin-table'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <Table hover responsive>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Company name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Completion date</th>
                                    <th>Games</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {liveDistributor.results &&
                                    liveDistributor.results.length > 0 &&
                                    liveDistributor.results.map((element) => (
                                      <tr key={element?.id ?? element?._id}>
                                        <td>
                                          <div className='user-name'>
                                            {element?.userid.name}
                                          </div>
                                        </td>
                                        <td>{element?.companyName}</td>
                                        <td>{element?.email}</td>
                                        <td>{element?.phoneNumber}</td>
                                        <td>{getDate(element?.createdAt)}</td>
                                        <td>{element?.numberOfGames}</td>
                                        <td>
                                          <Button className='back-work-btn'>
                                            Back to Work
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                              {liveDistributor?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDistributor?.page}
                                  totalpages={liveDistributor?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default DistributorRequest;

const RequestDistributor = ({
  getObjectData,
  initialObject,
  paginationStep,
  setLiveDistributors,
  liveDistributor,
  index,
}) => {
  console.log({ getObjectData });
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleStatusUpdate = async (contactId, emails, name, userid) => {
    const data = {
      contactId,
      status: "accept",
      email: emails,
      name: name,
      userid: userid.id,
    };
    const updateResponse = await updateStatusDistributor(data);
    if (updateResponse.msg === "success") {
      toast.success("Message To Completed!");
      getAllDistributor(setLiveDistributors, paginationStep);
      //setReply(false);
      //setMessage("");
    }
  };

  //console.log({ getObjectData });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message) {
      setErrorMessage("Plese type your reply");
      return;
    }
    if (!getObjectData.id) {
      setErrorMessage("Failed to process request");
      return;
    }
    setErrorMessage("");
    try {
      const userResponse = await adminInstance().post(
        "/admin-reply-to-distributor",
        {
          message: message,
          contactId: getObjectData.id,
          email: getObjectData.email,
          name: getObjectData?.userid.name,
        }
      );
      if (userResponse.status === 200) {
        toast.success("Message Sent!");
        getAllDistributor(setLiveDistributors, paginationStep);
        // setReply(false);
        setMessage("");
      }
      console.log({ userResponse });
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to reply.");
    }
  };

  return (
    <>
      <div className={`distributor-request-wrapper`}>
        <div className='distributor-left'>
          <div className='distributor-full-info'>
            <h5>{getObjectData?.userid.name}</h5>
            <div className='distributor-info-row'>
              <h6>Company name</h6>
              <p>{getObjectData?.companyName}</p>
            </div>
            <div className='distributor-info-row'>
              <h6>Email</h6>
              <a href='mailto:{getObjectData?.email}'>{getObjectData?.email}</a>
            </div>
            <div className='distributor-info-row'>
              <h6>Phone number</h6>
              <p>+{getObjectData?.phoneNumber}</p>
            </div>
            <div className='distributor-info-row'>
              <h6>number of Games</h6>
              <p>{getObjectData?.numberOfGames}</p>
            </div>
            <div className='distributor-info-row'>
              <h6>Request Date</h6>
              <p>{getDate(getObjectData?.createdAt)}</p>
            </div>

            <Button
              className='markcompleted-btn'
              onClick={() =>
                handleStatusUpdate(
                  getObjectData?.id,
                  getObjectData?.email,
                  getObjectData?.userid.name,
                  getObjectData?.userid
                )
              }>
              Mark as Compleated
            </Button>
          </div>
        </div>
        <div className='distributor-right w-100'>
          <div className='distributor-msg-header'>
            <h5>Message</h5>
            <span className='msg-circle'>
              <Form.Check type='checkbox' />
            </span>
          </div>
          <div className='distributor-msg-section'>
            <div className='distributor-msg-content'>
              <p>{getObjectData?.message}</p>
            </div>

            {/* <div className="distributor-msg-content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur laborum. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur laborum.
              </p>
            </div> */}
            {getObjectData.adminReply.map((element) => (
              <div
                className='admin-msg-content'
                key={element?.id ?? element?._id}>
                <div className='ans-by-grid'>
                  <div className='ans-by-img'>
                    <img src={user} alt='user' />
                  </div>
                  <div className='ans-by-heading'>
                    <h6>{element?.adminId["name"]}</h6>
                  </div>
                </div>
                <p>{element?.message}</p>
              </div>
            ))}
          </div>
          <div className='admin-typing-section'>
            <div className='reply-box'>
              {/* <Form className="reply-box-form">
                <FormControl
                  as="textarea"
                  type="text"
                  placeholder="Write message to Mike"
                  onFocus={handleDivColor}
                />
                <Button type="submit">Send</Button>
              </Form> */}
              <Form onSubmit={handleSubmit} className='reply-box-form'>
                <FormControl
                  as='textarea'
                  type='text'
                  placeholder='Write message to Mike'
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                {errorMessage && <p className='text-danger'>{errorMessage}</p>}
                <Button type='submit'>Send</Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
