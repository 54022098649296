import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab, Button, Table } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import {
  getTransactions,
  fetchGameRequest,
  fetchUserIp,
  updateUserIp,
  updateGameRequest,
  updateCoinsUser,
} from "../../utils/Api";
import cashicon from "../../assets/images/table/cash-app.svg";
import cryptoicon from "../../assets/images/table/crypto.svg";
// import eye from "../../assets/images/setting/eye.svg";
import { getDate, getTime } from "../../utils/index.js";
// import PagePagination from "../pagination/Pagination";
import coinicon from "../../assets/images/header/coin-icon.svg";
import "./playerProfile.css";
import PagePaginationNew from "../pagination/PaginationNew";
import TableLoader from "../loader/tableloader";
import NoData from "../noData";
import UserProfileHeader from "../pageheader/userProfileHeader";
import GameInfoPopup from "./GameInfoPopup";
import toast from "react-hot-toast";
import EditGameInfoPopup from "./EditGameInfoPopup.jsx";
import user from "../../assets/images/table/user2.png";

const PlayerProfile = () => {
  const location = useLocation();
  const {
    state: { userDetails },
  } = location;
  const [pageResult, setPageResult] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [passShowHide, setPassShowHide] = useState(false);
  const [tabView, setTabValue] = useState("deposit");
  const [gameInfo, setGameInfo] = useState(false);
  const [editGameInfo, setEditGameInfo] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [updateGame, setUpdateGame] = useState(null);
  const [walletCoins, setWalletCoins] = useState("");
  const [walletAmount, setWalletAmount] = useState(userDetails);
  
  const handleGameInfo = () => {
    setGameInfo(!gameInfo);
  };

  const handleEditGameInfo = () => {
    setEditGameInfo(!editGameInfo);
  };
  const initialPaginationValue = {
    limit: 0,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    _id: userDetails.id,
  };

  const [paginationValue, setPaginationValue] = useState(
    initialPaginationValue
  );

  useEffect(() => {
    const getTabsData = async () => {
      setLoading(true);
      let res = null;
      let resultFromKey = "";
      if (tabView === "approvedGames") {
        res = await fetchGameRequest({ ...paginationValue, status: "accept" });
        resultFromKey = "gameRequest";
      } else if (tabView === "ipAddress") {
        res = await fetchUserIp(paginationValue);
        resultFromKey = "result";
      } else {
        res = await getTransactions(tabView.toLowerCase(), paginationValue);
        resultFromKey = "transaction";
      }
      setLoading(false);
      if (res.status === 201 || res.status === 200) {
        const { results, limit, page, totalPages, totalResults } =
          res?.data[resultFromKey];
        setPageResult(results || []);
        console.log("pageResult", pageResult);
        const pageData = {
          limit,
          page,
          totalPages,
          totalResults,
          _id: userDetails.id,
        };
        if (JSON.stringify(paginationValue) !== JSON.stringify(pageData)) {
          setPaginationValue(pageData);
        }
      }
    };
    getTabsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationValue?.page, tabView, userDetails?.id]);

  const handleTabChange = (tab) => {
    setPaginationValue({ ...paginationValue, page: 1 });
    setTabValue(tab);
  };
  const handlePagination = (pagevalue) => {
    const copypagination = { ...paginationValue };
    if (pagevalue <= copypagination.totalPages) {
      copypagination.page = pagevalue;
    }
    setPaginationValue(copypagination);
  };

  const handleSelectGame = (data) => {
    console.log("data", data);
    setSelectedGame(data);
    handleGameInfo();
  };

  const handleEditSelectedGame = (data) => {
    setUpdateGame(data);
    handleEditGameInfo();
  };

  const updateGameInfo = async (id, data) => {
    const res = await updateGameRequest(id, data);
    console.log("res", res);

    if (res.code === 201) {
      setEditGameInfo(false);
    }
  };

  const handleBanUnban = async (isBan, _id, index) => {
    const res = await updateUserIp({ isBan, _id });
    if (res.status === 201) {
      const copyPageResult = JSON.parse(JSON.stringify(pageResult));
      copyPageResult[index].isBan = isBan;
      setPageResult(copyPageResult);
      const btcText = isBan ? "Ban" : "Unban";
      toast.success(`IP address ${btcText}`, {
        duration: 1500,
      });
    }
  };

  useEffect(() => {
    if (tabView === "ipAddress" && pageResult.length > 0) {
      const removeDuplicateIp = pageResult.filter(
        (v, i, a) =>
          a.findIndex((v2) => ["ipAddress"].every((k) => v2[k] === v[k])) === i
      );
      setPageResult(removeDuplicateIp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabView]);

  const updateWalletCoins = async (type) => {
    const res = await updateCoinsUser(
      userDetails?.id,
      type === "add" ? walletCoins : -walletCoins
    );
    console.log("res", res);

    if (res.data.code === 201) {
      setWalletCoins("");
      toast.success(
        `Coins ${type === "add" ? "Added" : "Removed"} Successfully`,
        {
          duration: 1500,
        }
      );
      const newCoinAmount = parseInt(res.data.coin);
      setWalletAmount({
        ...walletAmount,
        coin: newCoinAmount,
      });
    }
  };
  return (
    <AdminLayout>
      <div className="deposit-page">
        <UserProfileHeader
          pageTitle={userDetails?.nickName}
          totalData={parseInt(walletAmount?.coin)}
          userImage={userDetails?.avatar ||user}
          registeredDate={userDetails?.createdAt}
          lastLogin={userDetails?.lastLogin}
        />
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="admin-tab-section">
              <Tabs
                defaultActiveKey="deposit"
                id="uncontrolled-tab-example"
                onSelect={handleTabChange}
              >
                <Tab eventKey="deposit" title="Deposit">
                  <div className="admin-all-deposit">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading ? (
                          <>
                            {pageResult.length > 0 ? (
                              <>
                                <div className="admin-table">
                                  <Table hover responsive>
                                    <thead>
                                      <tr>
                                        <th>Payment</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Status</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Coins</th>
                                      </tr>
                                    </thead>
                                    {pageResult?.length > 0 ? (
                                      <>
                                        {pageResult?.map((data, i) => (
                                          <DepositRow
                                            data={data}
                                            key={data?.id ?? data?._id}
                                          />
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Table>
                                </div>
                                {paginationValue?.totalPages > 1 && (
                                  <div className="client-pagination-sec">
                                    <PagePaginationNew
                                      page={paginationValue?.page}
                                      totalpages={paginationValue?.totalPages}
                                      handlePagination={handlePagination}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </>
                        ) : (
                          <TableLoader />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="withdraw" title="Withdraw">
                  <div className="admin-cash-deposit">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading ? (
                          <>
                            {pageResult.length > 0 ? (
                              <>
                                <div className="admin-table">
                                  <Table hover responsive>
                                    <thead>
                                      <tr>
                                        <th>Payment</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Status</th>
                                        <th>Coins</th>
                                        <th>Currency</th>
                                        <th>Sum</th>
                                      </tr>
                                    </thead>

                                    {pageResult.length > 0 ? (
                                      <>
                                        {pageResult.map((data, i) => (
                                          <WithdrawRow
                                            data={data}
                                            key={data?.id ?? data?._id}
                                          />
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Table>
                                </div>
                                {paginationValue?.totalPages > 1 && (
                                  <div className="client-pagination-sec">
                                    <PagePaginationNew
                                      page={paginationValue?.page}
                                      totalpages={paginationValue?.totalPages}
                                      handlePagination={handlePagination}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </>
                        ) : (
                          <TableLoader />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="redeem" title="Game redeem">
                  <div className="redeem-record">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading ? (
                          <>
                            {pageResult.length > 0 ? (
                              <>
                                <div className="admin-table">
                                  <Table hover responsive>
                                    <thead>
                                      <tr>
                                        <th>Game provider</th>
                                        <th>Game id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Status</th>
                                        <th>Coins</th>
                                      </tr>
                                    </thead>
                                    {pageResult.length > 0 ? (
                                      <>
                                        {pageResult.map((data, i) => (
                                          <RedeemRechargeRow
                                            data={data}
                                            key={data?.id ?? data?._id}
                                          />
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Table>
                                </div>
                                {paginationValue?.totalPages > 1 && (
                                  <div className="client-pagination-sec">
                                    <PagePaginationNew
                                      page={paginationValue?.page}
                                      totalpages={paginationValue?.totalPages}
                                      handlePagination={handlePagination}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </>
                        ) : (
                          <TableLoader />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="recharge" title="Game recharge">
                  <div className="recharge-record">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading ? (
                          <>
                            {pageResult.length > 0 ? (
                              <>
                                <div className="admin-table">
                                  <Table hover responsive>
                                    <thead>
                                      <tr>
                                        <th>Game provider</th>
                                        <th>Game id</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Status</th>
                                        <th>Coins</th>
                                      </tr>
                                    </thead>
                                    {pageResult.length > 0 ? (
                                      <>
                                        {pageResult.map((data, i) => (
                                          <RedeemRechargeRow
                                            data={data}
                                            key={data?.id ?? data?._id}
                                          />
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Table>
                                </div>
                                {paginationValue?.totalPages > 1 && (
                                  <div className="client-pagination-sec">
                                    <PagePaginationNew
                                      page={paginationValue?.page}
                                      totalpages={paginationValue?.totalPages}
                                      handlePagination={handlePagination}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <NoData />
                            )}
                          </>
                        ) : (
                          <TableLoader />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="approvedGames" title="Games approved">
                  <div className="approved-game">
                    {!loading ? (
                      <div className="approved-game-content">
                        <div className="my-game-page wallet-user-recent-game-grid">
                          {pageResult.length > 0 ? (
                            <>
                              {pageResult.map((el) => (
                                <div
                                  className="recent-game_box"
                                  key={el?.id ?? el?._id}
                                >
                                  <img src={el?.gameId?.logo} alt="" />
                                  <div className="recent-game-detail">
                                    <div className="recent-game-detail-bottom  d-flex justify-content-between align-items-center flex-lg-column flex-md-column flex-xl-row">
                                      <div className="recent-game-detail-left">
                                        <p>{el?.gameId?.name}</p>
                                        <div className="recent-game-detail-img">
                                          {/* <img src={remote} alt="" /> */}
                                          <span>
                                            {el?.gameId?.playCount || 0} Games
                                          </span>
                                        </div>
                                      </div>
                                      <div className="footer-btns btn-sm d-flex">
                                        {/* {console.log("el", el)} */}
                                        <Button
                                          className="red-btn "
                                          onClick={() =>
                                            handleEditSelectedGame({
                                              gameUsername: el?.gameUsername,
                                              gamePassword: el?.gamePassword,
                                              ...el?.gameId,
                                              _id: el.id,
                                            })
                                          }
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          className="red-btn"
                                          onClick={() =>
                                            handleSelectGame({
                                              gameUsername: el?.gameUsername,
                                              gamePassword: el?.gamePassword,
                                              id: el?.gameId,
                                            })
                                          }
                                        >
                                          Info
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <NoData heading="No Game Data" />
                          )}
                        </div>

                        {paginationValue?.page > 1 && (
                          <div className="client-pagination-sec">
                            <PagePaginationNew
                              page={paginationValue?.page}
                              totalpages={paginationValue?.totalPages}
                              handlePagination={handlePagination}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <TableLoader />
                    )}
                  </div>
                </Tab>
                <Tab eventKey="ipAddress" title="Login Ip Address">
                  <div className="lastlogin-record">
                    <div className="row">
                      <div className="col-md-12">
                        {!loading ? (
                          <div className="admin-table lastlogin-table">
                            {pageResult.length > 0 &&
                            tabView === "ipAddress" ? (
                              <Table hover responsive>
                                <thead>
                                  <tr>
                                    <th>IP Address</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <LastLoginRow
                                  pageResult={pageResult}
                                  handleBanUnban={handleBanUnban}
                                />
                              </Table>
                            ) : (
                              <NoData heading="No IP Address Data" />
                            )}
                          </div>
                        ) : (
                          <TableLoader />
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="wallet" title="wallet">
                  <div className="wallet-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="wallet-info-section">
                          <h2>Wallet Amount</h2>
                          <div className="wallet-setting">
                            <label>Wallet</label>
                            <div className="wallet-input">
                              <input
                                type="number"
                                placeholder="Enter the Amount"
                                className="form-control"
                                name="wallet"
                                value={walletCoins}
                                onChange={(e) => setWalletCoins(e.target.value)}
                              />
                            </div>
                            <Button
                              type="sumit"
                              className="add-btn m-2"
                              onClick={() => updateWalletCoins("add")}
                            >
                              Add
                            </Button>
                            <Button
                              type="sumit"
                              className="add-btn m-2"
                              onClick={() => updateWalletCoins("remove")}
                            >
                              Remove{" "}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* <Tab eventKey='emailchange' title='Email & Password Change'>
               <div className="emaipassword-box">
                <div className="row">
                          <div className="col-md-6">
                            <div className="email-info">
  <h2>Change Email</h2>
  <div className="email-setting">
    <label>New Email</label>
    <div className="email-setting-input">
      <input
        type="email"
        placeholder="Email id"
        className="form-control"
        name="email"
      />
    </div>
  </div>
  <div className="email-setting">
    <label>Confirm Email</label>
    <div className="email-setting-input">
      <input
         type="email"
         placeholder="Email id"
         className="form-control"
         name="verifyemail"
      />
    </div>
  </div>
  <Button type='sumit'>Save changes</Button>
</div></div>
                          <div className="col-md-6">
                            <div className="email-password-info">
  <h2>Change password</h2>
  <div className="email-setting-password">
    <label>New Password</label>
    <div className="email-setting-input">
      <input
        type="password"
        placeholder="*******************"
        className="form-control"
        name="password"
      />
      <img
      src={eye}
      alt=''
      onClick={() => setPassShowHide(!passShowHide)}
    />
    </div>
  </div>
  <div className="email-setting-password">
    <label>Confirm Password</label>
    <div className="email-setting-input">
      <input
        type="password"
        placeholder="*******************"
        className="form-control"
        name="verifyPassword"
      />
      <img
      src={eye}
      alt=''
      onClick={() => setPassShowHide(!passShowHide)}
    />
    </div>
  </div>
  <Button type='sumit'>Save changes</Button>
</div>
</div>
                  </div>
                </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
        {gameInfo && (
          <GameInfoPopup
            handleGameInfo={handleGameInfo}
            gameInfo={gameInfo}
            selectedGame={selectedGame}
          />
        )}

        {editGameInfo && (
          <EditGameInfoPopup
            handleGameInfo={handleEditGameInfo}
            gameInfo={editGameInfo}
            selectedGame={updateGame}
            updateGameInfo={updateGameInfo}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default PlayerProfile;

const DepositRow = ({
  data: { payment, status, updatedAt, cryptoPrice, amountInUsd, coin },
}) => {
  let statusClass = "grey";
  let statusText = "Pending";
  if (status === "accept") {
    statusClass = "green";
    statusText = "Confirmed";
  } else if (status === "reject") {
    statusClass = "red";
    statusText = "Cancelled";
  }
  return (
    <>
      <tbody>
        <tr>
          <td>
            <div className="transaction-image">
              <img src={payment === "btc" ? cryptoicon : cashicon} alt="icon" />
            </div>
          </td>
          <td>{getDate(updatedAt)}</td>
          <td>{getTime(updatedAt)}</td>
          <td>
            <div className={`${statusClass}-status-text`}>
              <span>{statusText}</span>
            </div>
          </td>
          <td>{payment === "btc" ? "BTC" : "USD"}</td>
          <td>{payment === "btc" ? cryptoPrice : "$" + amountInUsd}</td>
          <td>
            <div className="transaction-coins">
              <img src={coinicon} alt="" /> {coin}
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
};

const WithdrawRow = ({
  data: { payment, status, updatedAt, cryptoPrice, amountInUsd, coin },
}) => {
  let statusClass = "grey";
  let statusText = "Pending";
  if (status === "accept") {
    statusClass = "green";
    statusText = "Confirmed";
  } else if (status === "reject") {
    statusClass = "red";
    statusText = "Cancelled";
  }

  return (
    <>
      <tbody>
        <tr>
          <td>
            <div className="transaction-image">
              <img src={payment === "btc" ? cryptoicon : cashicon} alt="icon" />
            </div>
          </td>
          <td>{getDate(updatedAt)}</td>
          <td>{getTime(updatedAt)}</td>
          <td>
            <div className={`${statusClass}-status-text`}>
              <span>{statusText}</span>
            </div>
          </td>
          <td>
            <div className="transaction-coins">
              <img src={coinicon} alt="" /> {coin}
            </div>
          </td>
          <td>{payment === "btc" ? "BTC" : "USD"}</td>
          <td>{payment === "btc" ? cryptoPrice : "$" + amountInUsd}</td>
        </tr>
      </tbody>
    </>
  );
};

const RedeemRechargeRow = ({ data: { status, updatedAt, coin, gameId } }) => {
  let statusClass = "grey";
  let statusText = "Pending";
  if (status === "accept") {
    statusClass = "green";
    statusText = "Confirmed";
  } else if (status === "reject") {
    statusClass = "red";
    statusText = "Cancelled";
  }
  return (
    <>
      <tbody>
        <tr>
          <td>
            <div className="game-provider">
              {gameId?.brandName?.name || ""}{" "}
            </div>
          </td>
          <td>mike.johnsoncasinoplayer</td>
          <td>{getDate(updatedAt)}</td>
          <td>{getTime(updatedAt)}</td>
          <td>
            <div className={`${statusClass}-status-text`}>
              <span>{statusText}</span>
            </div>
          </td>
          <td>
            <div className="transaction-coins">
              <img src={coinicon} alt="" /> {coin}
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
};

const LastLoginRow = ({ pageResult, handleBanUnban }) => {
  return (
    <tbody>
      {pageResult.map(({ ipAddress, isBan, id }, i) => {
        let btcClass = "confirm-btn";
        let btcText = "Ban";
        if (isBan) {
          btcClass = "cancle-btn";
          btcText = "Unban";
        }
        return (
          <>
            <tr key={id}>
              <td>{ipAddress}</td>
              <td>
                <Button
                  className={`${btcClass}`}
                  onClick={() => handleBanUnban(!isBan, id, i)}
                >
                  {btcText}
                </Button>
              </td>
            </tr>
          </>
        );
      })}
    </tbody>
  );
};
