import "./header.css";
// import bellicon from "../../assets/images/header/bell-icon.svg";

const NotificationIcon = ({ handleNotification, showBellNotify }) => {
  return (
    <>
      <div className='notification-btn' onClick={handleNotification}>
        {/* <img src={bellicon} alt='bell icon' onClick={() => handleNotification()}/> */}
        <h4 onClick={() => handleNotification()}>Notification</h4>
        {showBellNotify && <span className='red-icon'></span>}
      </div>
    </>
  );
};

export default NotificationIcon;
