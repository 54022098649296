import { useState, useEffect, useRef, useCallback } from "react";
import { Button, Spinner, Offcanvas } from "react-bootstrap";
import userImg from "../../assets/images/table/user3.png";
import user from "../../assets/images/chat/user.png";
import coinicon from "../../assets/images/header/coin-icon.svg";
import "./support.css";
import AdminLayout from "../layout/layout";
import { socket } from "../../config/socket";
import SearchBoxComponent from "../search/searchBoxComponent";
import { getChatByUser, getChats } from "../../utils/Api";
import timeDifference from "../../utils/timeDuration";
import { dateTimeLine, getDate, getTime,changeCurrencyFormat } from "../../utils";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

const SupportChat = () => {
  const {user: useData} =  useContext(UserContext)
  const [msg, setMsg] = useState("");
  const [userID, setUserID] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const messagesEndRef = useRef(null);
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterUser, setFilterUser] = useState([]);
  const [messages, setMessages] = useState({});
  const [showUserList, setShowUserList] = useState(false);
  const [chatMainArray, setMainChatArray] = useState([]);

  const handleUserList = () => {
    setShowUserList(!showUserList);
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };
  const getMessagesByDate = (arrayData) => {
    let obj = {};
    arrayData.forEach((el) => {
      const day = dateTimeLine(el?.createdAt);
      if (obj[day.replaceAll(" ", "_")]) {
        obj[day.replaceAll(" ", "_")].push(el);
      } else {
        obj[day.replaceAll(" ", "_")] = [el];
      }
    });
    setMessages(obj);
  };
  const handleSelectUser = async (user, index) => {
    setIsLoading(true);
    const res = await getChatByUser(user?._id);
    setIsLoading(false);
    setActive(index);
    if (res.status === 200) {
      let copyUsers = [...users];
      copyUsers[index].chats = res.data;
      copyUsers[index].unRead = 0;
      getMessagesByDate(res.data);
      setUsers(copyUsers);
      setUserID(user?._id);
      setSelectedIndex(index);

    }
    setShowUserList(!showUserList);
  };

  // console.log('messages---',messages['12 August 2022']);

  const handleSendMsg = (e) => {
    e.preventDefault();
    if (users.length > 0 && msg) {
      const messageData = {
        message: msg,
        isAdmin: true,
        userId: userID,
        createdAt: new Date(),
      };
      let copyUsers = [...users];
      socket.emit("adminReply", messageData);
      const copyMessage = JSON.parse(JSON.stringify(messages));
      copyMessage["Today"].push(messageData);
      setMessages(copyMessage);
      copyUsers[selectedIndex].chats.push(messageData);
      setMsg("");
    }
  };
  const getAllChats = async () => {
    const res = await getChats();
    if (res.status === 200) {
      // scrollToBottom();
      if (Array.isArray(res.data)) {
        let result = null;
        if (res?.data?.length > 0) {
          result = await getChatByUser(res.data[0].userDetail[0]?._id);
        }
        res.data[0].chats = result.data;
        getMessagesByDate(result.data);
        res.data[0].unRead = 0;
        setUserID(res.data[0].userDetail[0]._id);
        setUsers(res.data);
        setFilterUser(res.data.map((el,i) => {
          el.originalIndex = i;
          return el;
        }));
        setMainChatArray(res.data);
        setSelectedIndex(0);
      }
      return res.data;
    }
  };

  console.log({filterUser})

  const filterChat = useCallback(
    (userInput = "") => {
      let filterUser = users.filter((el) =>
        el?.userDetail[0]?.name.toLowerCase().includes(userInput.toLowerCase())
      );
   
      setFilterUser(filterUser);
    },
    [users]
  );


  useEffect(() => {
    socket.on("newAdminMsg", (msgobj) => {
      let { data } = msgobj;
      data.createdAt = new Date();
      const { userId } = data;
      const copyUsers = [...chatMainArray];
      if (data?.userData) {
        const unRead = copyUsers.length === 0 ? 0 : 1;
        let obj = {
          userDetail: [data?.userData],
          chatData: data,
          chats: [data],
          _id: data.userId,
          unRead: unRead,
        };
        copyUsers.push(obj);
        setUserID(userId);
      } else {
        // scrollToBottom()
        const findUserIndex = users.findIndex(
          (el) => el?.userDetail[0]._id === userId
        );
        if (findUserIndex === selectedIndex) {
          data.isRead = true;
          const copyMessage = JSON.parse(JSON.stringify(messages));
          copyMessage["Today"].push(data);
          setMessages(copyMessage);
        } else {
          copyUsers[findUserIndex].unRead += 1;
        }

        copyUsers[findUserIndex].chats.push(data);
      }
      setMainChatArray(copyUsers);
    });
    return () => {
      socket.off("newAdminMsg");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    getAllChats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      <div className="admin-live-chat">
        <div className="container">
        <div className="admin-content-wrapper">
            <div className="admin-section-title">
              <h3>Live chat</h3>
              <Button onClick={handleUserList}>
                <i class="las la-ellipsis-v"></i> User List
              </Button>
            </div>
            <div className="admin-support-adminchat-content">
              <UserList
                handleSelectUser={handleSelectUser}
                active={active}
                filterChat={filterChat}
                filterUser={filterUser}
              />
              <div className="adminchat">
                <div className="adminchat-header">
                  {filterUser.length > 0 ? (
                    <>
                      <div className="adminchat-header-user">
                        <img
                          src={
                            selectedIndex !== -1 &&
                            filterUser.length > 0 &&
                            filterUser[selectedIndex]?.userDetail[0]?.avatar
                              ? filterUser[selectedIndex]?.userDetail[0]?.avatar
                              : userImg
                          }
                          alt="avatar"
                        />
                        <div className="adminchat-header-user-info">
                          <h6>
                            {chatMainArray[selectedIndex]?.userDetail[0]?.name}
                          </h6>
                          <p>
                            {chatMainArray[selectedIndex]?.userDetail[0]?.email}
                          </p>
                        </div>
                      </div>
                      <div className="adminchat-header-sub-info">
                        <div className="adminchat-header-sub-info-box">
                          <p>Registration</p>
                          <h6>
                            {getDate(
                              chatMainArray[selectedIndex]?.userDetail[0]?.createdAt
                            )}
                          </h6>
                        </div>
                        {/* <div className="adminchat-header-sub-info-box">
                          <p>Games</p>
                          <h6>20</h6>
                        </div> */}
                        <div className="adminchat-header-sub-info-box">
                          <p>Balance</p>
                          <h6 className="bold-text">
                            <img src={coinicon} alt="coinimage" />{changeCurrencyFormat(chatMainArray[selectedIndex]?.userDetail[0]?.coin)}
                          </h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="adminchat-header-user">
                        <img
                          style={{ visibility: "hidden" }}
                          src={""}
                          alt="avatar"
                        />
                        <div className="adminchat-header-user-info">
                          <h6>{"No Chats"}</h6>
                          <p>{""}</p>
                        </div>
                      </div>
                      <div className="adminchat-header-sub-info">
                        <div className="adminchat-header-sub-info-box">
                          <p>{""}</p>
                          <h6>{""}</h6>
                        </div>
                        <div className="adminchat-header-sub-info-box">
                          <p>{""}</p>
                          <h6>{""}</h6>
                        </div>
                        <div className="adminchat-header-sub-info-box">
                          <p>{""}</p>
                          <h6 className="bold-text">
                            {/* <img src={coinicon} alt='coinimage' /> {''} */}
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="adminchat-history">
                  <ul id="chatbox" className={isLoading && "center_loader"}>
                    {isLoading ? (
                      <Spinner animation="border" variant="dark" />
                    ) : (
                      <>
                        {filterUser && filterUser.length > 0 && (
                          <>
                            {Object.keys(messages).map((key) => (
                              <>
                                <p className="msg-timeline">
                                  {dateTimeLine(
                                    messages[key].at(-1).createdAt,
                                    "render"
                                  )}
                                </p>
                                {messages[key].map((item) =>
                                  item.isAdmin ? (
                                    <>
                                      <AdminMessage
                                      key={item?.id?? item?._id}
                                        message={item?.message}
                                        userImg={useData?.avatar ?? user}
                                        createdAt={item?.createdAt}
                                        messagesEndRef={messagesEndRef}
                                        scrollToBottom={scrollToBottom}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <UserMessage
                                        message={item?.message}
                                        userImg={filterUser[selectedIndex]?.userDetail[0]?.avatar ?? userImg}
                                        createdAt={item?.createdAt}
                                        messagesEndRef={messagesEndRef}
                                        scrollToBottom={scrollToBottom}
                                      />
                                    </>
                                  )
                                )}
                              </>
                            ))}
                            <div />
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </div>

                <div className="adminchat-message clearfix">
                  <form>
                    <input
                      className="form-control"
                      type="text"
                      name="message"
                      id="message-to-send"
                      value={msg}
                      placeholder="Write message to Mike"
                      onChange={(e) => setMsg(e.target.value)}
                    />
                    <Button type="submit" onClick={handleSendMsg}>
                      Send
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div> 
          
        </div>
      </div>
      {window.innerWidth <= "766" ? (
        <Offcanvas
          show={showUserList}
          onHide={handleUserList}
          placement="end"
          className="mobile-user-list"
          backdrop={true}
          keyboard={true}
        >
          <UserList
            handleSelectUser={handleSelectUser}
            active={active}
            filterChat={filterChat}
            filterUser={filterUser}
          />
        </Offcanvas>
      ) : (
        ""
      )}
    </AdminLayout>
  );
};

const UserList = ({ filterChat, filterUser, handleSelectUser, active }) => {
  return (
    <div className="people-list" id="people-list">
      <div className="people-list-header">
        <div className="quick-request">
          <h6>Quick Requests</h6>
        </div>
        <SearchBoxComponent searchFunction={filterChat} />
      </div>
      <div className="people-list-wrapper">
        <ul className="list">
          {filterUser &&
            filterUser.map((el, i) => (
              <li
                onClick={() => handleSelectUser(el?.userDetail[0], el.originalIndex)}
                key={`item-${i}`}
                className={`clearfix ${active === i && "active"}`}
              >
                <div className="about">
                  <img src={el?.userDetail[0]?.avatar || userImg} alt="avatar" />
                  <div className="about-name-email">
                    <div className="name">{el?.userDetail[0]?.name}</div>
                    <div className="email-about">
                      {el?.userDetail[0]?.email}
                    </div>
                  </div>
                </div>
                <div className="msg-time-grid">
                  <div className="msg-time-box">
                    {el?.unRead && el?.chats && el?.chats?.length > 0
                      ? timeDifference(
                          el?.chats[el?.chats.length - 1]?.createdAt
                        )
                      : ""}
                  </div>
                  {el?.unRead ? (
                    <>
                      {" "}
                      <div className="new-msg-count">{el?.unRead}</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

const AdminMessage = ({
  message,
  userImg,
  messagesEndRef,
  createdAt,
  scrollToBottom,
  data,
}) => {
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  return (
    <li className="clearfix">
      <div className="admin-message" ref={messagesEndRef}>
        <div className="message-section">
          <div className="message-box">
            <h6>{message}</h6>
          </div>
          <p>{getTime(createdAt)}</p>
        </div>
        <div className="admin-message-icon">
          <img src={userImg} alt="user" />
        </div>
      </div>
    </li>
  );
};
const UserMessage = ({
  message,
  userImg,
  createdAt,
  messagesEndRef,
  scrollToBottom,
}) => {
  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);
  return (
    <li>
      <div className="user-message" ref={messagesEndRef}>
        <div className="user-message-icon">
          <img src={userImg} alt="user" />
        </div>
        <div className="message-section">
          <div className="message-box">
            <h6>{message}</h6>
          </div>
          <p>{getTime(createdAt)}</p>
        </div>
      </div>
    </li>
  );
};

export default SupportChat;
