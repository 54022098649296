import { useState } from 'react';
import { Form, Button, FormControl } from 'react-bootstrap';
import useDebounce from '../../hooks/useDebounce';
import './search.css';

const SearchBoxComponent = ({ searchFunction }) => {
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [loader] = useDebounce(search, searchFunction, 500, count);

  const handleChnage = (e) => {
    if (count === 0) {
      setCount(count + 1);
    }
    setSearch(e.target.value);
  };

  console.log({ loader });

  return (
    <div className='search-wrapper'>
      <div className='search-box'>
        <Form className='search-box-form'>
          <Button>
            <i className='las la-search' />
          </Button>
          <FormControl
            type='search'
            onChange={handleChnage}
            placeholder='Search'
            aria-label='Search'
          />
        </Form>
      </div>
    </div>
  );
};

export default SearchBoxComponent;
