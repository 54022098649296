import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { LoginSchema } from "../../validationSchema/authSchema";
import { login } from "../../utils/Api";
import UserContext from "../../context/UserContext";
import { Form, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import logo from "../../assets/images/header/logo3d.png";
import "./login.css";

const Login = () => {
  const navigate = useNavigate();
  const [pass, setPass] = useState("password");
  const handlePass = () => {
    setPass(!pass);
  };
  const { setTokens, setUser, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLoginPage(true);
    }, 200);
    const tokenData = localStorage.getItem("admintoken");
    let checkTokenData = false;
    if (tokenData) {
      const tokenParsed = JSON.parse(tokenData) || {};
      const { token, expires } = tokenParsed?.access;
      if (token && new Date() < new Date(expires)) {
        checkTokenData = true;
      }
    }

    if (!checkTokenData) return;
    navigate("/players");
  }, [navigate, user?.name]);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) });

  const loginUser = async (values) => {
    try {
      setIsLoading(true);
      const res = await login(values);
      setIsLoading(false);

      if (res.message === "Incorrect email") {
        setError(
          "email",
          { type: "custom", message: "Invalid email" },
          { shouldFocus: true }
        );
      } else if (res.message === "Incorrect password") {
        setError(
          "password",
          { type: "custom", message: "Invalid password" },
          { shouldFocus: true }
        );
      } else if (res.message === "Not found") {
        setError(
          "password",
          { type: "custom", message: "Invalid request" },
          { shouldFocus: true }
        );
      } else if (res.message === "Invalid Credentials") {
        toast.error(res.message)
      } else if (res.user) {
        localStorage.setItem("admintoken", JSON.stringify(res.tokens));
        setTokens(res.tokens);
        setUser(res.user);
        if (res.user.role === "cashier") {
          navigate("/game-recharge");
        } else if (res.user.role === "customerService") {
          navigate("/game-request");
        } else {
          navigate("/players");
        }
        toast.success("Login Successfully");
      }
      console.log("Res =>", res);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      {showLoginPage && (
        <div className='login'>
          <div className='auth-page'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='login-form'>
                    <Link to='/players'>
                      <img src={logo} alt='logo' />
                    </Link>
                    <h1>Admin Login</h1>
                    <p className='auth-para'>
                      Welcome back to King of Fish Games Admin
                    </p>
                    <div className='login-box'>
                      <Form onSubmit={handleSubmit(loginUser)}>
                        <Form.Group className='form-group'>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type='text'
                            name='email'
                            className={errors.email ? "error-field" : ""}
                            {...register("email")}
                            autoComplete='off'
                          />
                          {errors?.email ? (
                            <p className='error-text'>
                              {errors?.email?.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <Form.Group className='form-group'>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type={`${pass ? `password` : `text`}`}
                            name='password'
                            className={errors.password ? "error-field" : ""}
                            {...register("password")}
                            autoComplete='off'
                          />
                          <span className='input-icon' onClick={handlePass}>
                            <i
                              class={
                                pass ? "las la-eye" : "las la-eye-slash"
                              }></i>
                          </span>
                          {errors?.password ? (
                            <p className='error-text'>
                              {errors.password.message}
                            </p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <div className='forget-password'>
                          <div className='remember-me'>
                            <Form.Check
                              type='checkbox'
                              {...register("remember")}
                              label='Remember me'
                            />
                          </div>
                          <Link to='/forget-password'>Forget Password?</Link>
                        </div>
                        <div className='login-button'>
                          <Button type='submit' className='l-btn'>
                            {isLoading ? (
                              <Spinner animation='border' />
                            ) : (
                              "Login"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
