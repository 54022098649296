import { capitalizeFirstLetter } from "../../utils";
import { Modal } from "react-bootstrap";

const GameInfoPopup = ({ gameInfo, handleGameInfo,selectedGame }) => {
  console.log("Info Popup Data------>", selectedGame);
    return (
      <Modal
        size="lg"
        className="game-info-popup"
        show={gameInfo}
        onHide={() => handleGameInfo()}
        centered
      >
        <Modal.Header closeButton>
          <div className="game-info-banner">
            <img src={selectedGame?.id?.logo} alt="banner" />
            <div className="game-info-name">
              <h2>{selectedGame?.name||''}</h2>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="game-info-wrapper">
            <div className="game-info-content">
              <h6>Game info</h6>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Games type</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.id?.gameCategory}</p>
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Devices</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.id?.device?.map((el,i)=>`${capitalizeFirstLetter(el)}${selectedGame?.device?.length !== i+1 ? ', ':''}`) || ''}</p>
                </div>
              </div>
            </div>
            {selectedGame?.id && <div className="account-info-content">
              <h6>Account info</h6>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>User name</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.gameUsername || ''}</p>
                </div>
              </div>
              <div className="game-info-grid">
                <div className="game-info-left">
                  <p>Password</p>
                </div>
                <div className="game-info-right">
                  <p>{selectedGame?.gamePassword || ''}</p>
                </div>
              </div>
            </div>}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  export default GameInfoPopup