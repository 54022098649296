import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { Tabs, Tab, Button } from "react-bootstrap";
import AdminLayout from "../layout/layout";
import SearchBoxComponent from "../search/searchBoxComponent";
import {
  getDepositList,
  getTotalCoins,
  updateDepositStatus,
} from "../../utils/Api";
import usericon1 from "../../assets/images/table/user3.png";
import cashicon from "../../assets/images/table/cash-app.svg";
import cryptoicon from "../../assets/images/table/crypto.svg";
import { getDate } from "../../utils/index.js";
// import PagePagination from "../pagination/Pagination";
import coinicon from "../../assets/images/header/coin-icon.svg";
import "./deposit.css";
import PageHeader from "../pageheader/pageheader";
import PagePaginationNew from "../pagination/PaginationNew";
import TableLoader from "../loader/tableloader";
import NoData from "../noData";
import ViewDepositPopup from "./ViewDepositPopup.jsx";

const initialObj = {
  first: 1,
  last: "",
  next: 2,
  prev: 1,
  activeindex: 1,
  skip: 0,
  limit: 10,
  status: "idle",
};

const Deposit = () => {
  const [liveDeposit, setLiveDepositList] = useState([]);

  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const [viewStatus, setviewStatus] = useState("idle");
  const [totalData, setTotalData] = useState({});
  const [loading, setLoading] = useState(false);

  const getDeposit = async (paginationObj, step) => {
    setLoading(true);
    const result = await getDepositList(setLiveDepositList, paginationObj);
    setLoading(false);
    if (result && step === "first") {
      let copyObj = { ...paginationObj };
      copyObj.last = result?.totalPages;
      copyObj.status = viewStatus;
      setPaginationStep(copyObj);
    }
  };

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getDeposit(copyobj);
    setPaginationStep(copyobj);
  };

  const searchDeposit = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      searchString.status = viewStatus;
      await getDepositList(setLiveDepositList, searchString);
    },
    [paginationStep, viewStatus]
  );

  const handleDepositNewTabs = (event) => {
    setviewStatus("idle");
    // { ...paginationStep, status: "idle", paymentStatus: "" },
    if (event === "cashapp") {
      getDeposit({ ...initialObj });
      setPaginationStep(
        { ...initialObj, status: "idle", paymentStatus: "cashApp" },
        "first"
      );
    } else if (event === "crypto") {
      getDeposit({ ...initialObj });
      setPaginationStep(
        { ...initialObj, status: "idle", paymentStatus: "btc" },
        "first"
      );
    } else if (event === "all") {
      getDeposit({ ...initialObj });
      setPaginationStep(
        { ...initialObj, status: "idle", paymentStatus: "" },
        "first"
      );
    }
  };

  useEffect(() => {
    getDeposit(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialObj]);

  const handleStatusUpdate = async (updateId, index) => {
    const data = { status: "accept" };
    const updateResponse = await updateDepositStatus(updateId, data);
    if (updateResponse.transaction.status === "accept") {
      const copyDepost = JSON.parse(JSON.stringify(liveDeposit));
      copyDepost.results.splice(index, 1);
      setLiveDepositList(copyDepost);
      toast.success("DepositedRequest is Accepted!");
    }
  };

  const getTotalCoinsValue = async () => {
    const res = await getTotalCoins("transaction", {
      status: "accept",
      transaction: "deposit",
    });
    if (res.status === 200) {
      setTotalData(res.data.result[0] || {});
    }
  };
  useEffect(() => {
    getTotalCoinsValue();
  }, []);
  return (
    <AdminLayout>
      <div className="deposit-page">
        <PageHeader pageTitle="Deposit Request" totalData={totalData} />
        <div className="container">
          <div className="admin-content-wrapper">
            <div className="admin-tab-section">
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                onSelect={handleDepositNewTabs}
              >
                <Tab eventKey="all" title="All">
                  <div className="admin-all-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                            <div className="overflow-auto">
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return element.payment === "cashApp" ? (
                                      <>
                                        <AllDepositRow
                                          getObjectData={element}
                                          handleStatusUpdate={
                                            handleStatusUpdate
                                          }
                                          index={index}
                                          key={element?.id ?? element?._id}
                                        />
                                      </>
                                    ) : (
                                      <CryptoDepositRow
                                        getObjectData={element}
                                        handleStatusUpdate={handleStatusUpdate}
                                        index={index}
                                        key={element?.id ?? element?._id}
                                      />
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="cashapp" title="Cash app">
                  <div className="admin-cash-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <CashDepositRow
                                        getObjectData={element}
                                        handleStatusUpdate={handleStatusUpdate}
                                        index={index}
                                        key={element?.id ?? element?._id}
                                      />
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="crypto" title="Crypto">
                  <div className="admin-crypto-deposit">
                    <div className="admin-game-table">
                      <div className="row">
                        <div className="col-md-12">
                          <SearchBoxComponent searchFunction={searchDeposit} />
                          {!loading ? (
                            <>
                              <div className="admin-custom-table">
                                {liveDeposit.results &&
                                liveDeposit.results.length > 0 ? (
                                  liveDeposit.results.map((element, index) => {
                                    return (
                                      <CryptoDepositRow
                                        getObjectData={element}
                                        handleStatusUpdate={handleStatusUpdate}
                                        index={index}
                                        key={element?.id ?? element?._id}
                                      />
                                    );
                                  })
                                ) : (
                                  <NoData heading="No Deposit Data" />
                                )}
                              </div>
                              {liveDeposit?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveDeposit?.page}
                                  totalpages={liveDeposit?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Deposit;

const AllDepositRow = ({ getObjectData, handleStatusUpdate, index }) => {
  const [viewDepositRequest, setViewDepositRequest] = useState(false);
  const [selectedSpecificDepositRequest, setSelectedDepositRequest] =
    useState();
  const closePopup = () => {
    setViewDepositRequest(false);
  };
  const handleViewRequest = (data, index) => {
    setSelectedDepositRequest({ data: data, index: index });
    setViewDepositRequest(true);
  };
  return (
    <div className="admin-custom-row">
      <div className="user-name-grid">
        <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
        <div className="user-name-box">
          <h6>{getObjectData.userId.name}</h6>
          <p>{getObjectData.userId.email}</p>
        </div>
      </div>
      <div className="currency-type">
        <img src={cashicon} alt="game" />
      </div>
      <div className="request-date">
        <p>Date</p>
        <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          <p>{getObjectData.transaction}</p>
          <h6>${getObjectData.amountInUsd}</h6>
        </div>
      </div>
      <div className="coins-amt-grid">
        <div className="coins-amt">
          <p>Coins</p>
          <h6>
            <img src={coinicon} alt="coin" /> {getObjectData.coin}
          </h6>
        </div>
      </div>
      <div className="single-action-btn d-flex gap-2">
        <Button
          className="view-button bg-primary"
          onClick={() => handleViewRequest(getObjectData, index)}
        >
          View
        </Button>
        <Button
          className="confirm-btn"
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}
        >
          Confirm
        </Button>
      </div>
      <ViewDepositPopup
        selectedSpecificDepositRequest={selectedSpecificDepositRequest}
        viewDepositRequest={viewDepositRequest}
        closePopup={closePopup}
        handleStatusUpdate={handleStatusUpdate}
      />
    </div>
  );
};

const CashDepositRow = ({ getObjectData, handleStatusUpdate, index }) => {
  return (
    <div className="admin-custom-row">
      <div className="user-name-grid">
        <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
        <div className="user-name-box">
          <h6>{getObjectData.userId.name}</h6>
          <p>{getObjectData.userId.email}</p>
        </div>
      </div>
      <div className="currency-type">
        <img src={cashicon} alt="game" />
      </div>
      <div className="request-date">
        <p>Date</p>
        <h6>{getDate(getObjectData.createdAt)}, 10:00</h6>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          <p>Deposit</p>
          <h6>${getObjectData.amountInUsd}</h6>
        </div>
      </div>
      <div className="coins-amt-grid">
        <div className="coins-amt">
          <p>Coins</p>
          <h6>
            <img src={coinicon} alt="coin" /> {getObjectData.coin}
          </h6>
        </div>
      </div>
      <div className="single-action-btn">
        <Button
          className="confirm-btn"
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

const CryptoDepositRow = ({ getObjectData, handleStatusUpdate, index }) => {
  return (
    <div className="admin-custom-row">
      <div className="user-name-grid">
        <img src={getObjectData?.userId?.avatar || usericon1} alt="game" />
        <div className="user-name-box">
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className="currency-type">
        <img src={cryptoicon} alt="game" />
      </div>
      <div className="request-date">
        <p>Date</p>
        <h6>{getDate(getObjectData?.createdAt)}, 10:00</h6>
      </div>
      <div className="transaction-amt-grid">
        <div className="transaction-amt">
          <p>Deposit</p>
          <h6>BTC {getObjectData?.cryptoPrice}</h6>
        </div>
      </div>
      <div className="coins-amt-grid">
        <div className="coins-amt">
          <p>Coins</p>
          <h6>
            <img src={coinicon} alt="coin" /> {getObjectData?.coin}
          </h6>
        </div>
      </div>
      <div className="single-action-btn">
        <Button
          className="confirm-btn"
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
