import { useState, useCallback,useEffect } from 'react';
import toast from 'react-hot-toast';
import { Tabs, Tab, Button } from 'react-bootstrap';
import AdminLayout from '../layout/layout';
import SearchBoxComponent from '../search/searchBoxComponent';
import { getGameRedeemList, updateRedeemGameStatus } from '../../utils/Api';
import { getDate } from '../../utils/index.js';
import usericon1 from '../../assets/images/table/user3.png';
import gameicon from '../../assets/images/table/game.png';
// import PagePagination from "../pagination/Pagination";
import coinicon from '../../assets/images/header/coin-icon.svg';
import './game-redeem.css';
import PagePaginationNew from '../pagination/PaginationNew';
import NoData from '../noData/index';
import TableLoader from '../loader/tableloader';

const initialObj = {
  first: 1,
  last: '',
  next: 2,
  prev: 1,
  activeindex: 1,
  skip: 0,
  limit: 10,
  status: 'idle',
};
const GameRedeem = () => {
  const [liveGameRedeem, setLiveGameRedeemList] = useState([]);
  const [paginationStep, setPaginationStep] = useState({ ...initialObj });
  const [viewStatus, setviewStatus] = useState('idle');
  const [laoding, setLoading] = useState(false);

  const getGameRedeem = useCallback(
    async (paginationObj, step) => {
      setLoading(true);
      const result = await getGameRedeemList(
        setLiveGameRedeemList,
        paginationObj
      );
      setLoading(false);
      if (result && step === 'first') {
        let copyObj = { ...paginationObj };
        copyObj.last = result?.totalPages;
        copyObj.status = viewStatus;
        setPaginationStep(copyObj);
      }
    },
    [viewStatus]
  );

  const handlePagenation = (index) => {
    let copyobj = { ...paginationStep };
    copyobj.activeindex = index;
    if (index < paginationStep.last) {
      copyobj.next = index + 1;
    }
    if (index !== 1) {
      copyobj.prev = index - 1;
    }
    copyobj.skip = paginationStep?.limit * (index - 1);
    getGameRedeem(copyobj);
    setPaginationStep(copyobj);
  };

  const searchGameReddem = useCallback(
    async (value) => {
      const searchString = paginationStep;
      searchString.name = value;
      searchString.status = viewStatus;
      await getGameRedeem(setLiveGameRedeemList, searchString);
    },
    [getGameRedeem, paginationStep, viewStatus]
  );

  const handleWithdrawNewTabs = (event) => {
    if (event === 'confirmed') {
      setviewStatus('accept');
      getGameRedeem({ ...paginationStep,status: 'accept',activeindex:1 });
      setPaginationStep(
        { ...paginationStep, status: 'accept', paymentStatus: 'coin',activeindex:1 },
        'first'
      );
    } else if (event === 'canceled') {
      setviewStatus('reject');
      getGameRedeem({ ...paginationStep ,status: 'reject',activeindex:1});
      setPaginationStep(
        { ...paginationStep, status: 'reject', paymentStatus: 'coin',activeindex:1 },
        'first'
      );
    } else if (event === 'new') {
      setviewStatus('idle');
      getGameRedeem({ ...paginationStep,status: 'idle',activeindex:1 });
      setPaginationStep(
        { ...paginationStep, status: 'idle', paymentStatus: 'coin' ,activeindex:1},
        'first'
      );
    }
  };

    useEffect(() => {
    getGameRedeem(initialObj, "first");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialObj]);

  const handleStatusUpdate = async (updateId, index) => {
    const data = { status: 'reject' };
    const updateResponse = await updateRedeemGameStatus(updateId, data);
    if (updateResponse.transaction.status === 'reject') {
      const copyDepost = JSON.parse(JSON.stringify(liveGameRedeem));
      copyDepost.results.splice(index, 1);
      setLiveGameRedeemList(copyDepost);
      getGameRedeem(paginationStep, 'first');
      toast.success(' Request is Canceled!');
    }
  };

  const handleStatusConfirmUpdate = async (updateId, index) => {
    const data = { status: 'accept' };
    const updateResponse = await updateRedeemGameStatus(updateId, data);
    if (updateResponse.transaction.status === 'accept') {
      const copyDepost = JSON.parse(JSON.stringify(liveGameRedeem));
      copyDepost.results.splice(index, 1);
      setLiveGameRedeemList(copyDepost);
      getGameRedeem(paginationStep, 'first');
      toast.success(' Request is Confirm!');
    }
  };

  return (
    <AdminLayout>
      <div className='game-redeem-page'>
        <div className='container'>
          <div className='admin-content-wrapper'>
            <div className='admin-section-title'>
              <h3>Game Redeem Request</h3>
            </div>
            <div className='admin-tab-section'>
              <Tabs
                defaultActiveKey='new'
                id='uncontrolled-tab-example'
                onSelect={handleWithdrawNewTabs}>
                <Tab eventKey='new' title='New'>
                  <div className='admin-recharge-request'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent
                            searchFunction={searchGameReddem}
                          />
                          {!laoding ? (
                            <>
                              <div className='admin-custom-table'>
                                {liveGameRedeem.results &&
                                liveGameRedeem.results.length > 0 ? (
                                  liveGameRedeem.results.map(
                                    (element, index) => {
                                      return (
                                        <>
                                          <RedeemRequestRow
                                            key={element?.id ?? element?._id}
                                            getObjectData={element}
                                            handleStatusUpdate={
                                              handleStatusUpdate
                                            }
                                            handleStatusConfirmUpdate={
                                              handleStatusConfirmUpdate
                                            }
                                            index={index}
                                          />
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <NoData heading='No Redeem Data' />
                                )}
                              </div>
                              {liveGameRedeem?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveGameRedeem?.page}
                                  totalpages={liveGameRedeem?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='confirmed' title='Confirmed'>
                  <div className='admin-recharge-confirm'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent
                            searchFunction={searchGameReddem}
                          />
                          {!laoding ? (
                            <>
                              <div className='admin-custom-table'>
                                {liveGameRedeem.results &&
                                liveGameRedeem.results.length > 0 ? (
                                  liveGameRedeem.results.map(
                                    (element, index) => {
                                      return (
                                        <>
                                          <RedeemConfirmRow
                                            key={element?.id ?? element?._id}
                                            getObjectData={element}
                                            handleStatusUpdate={
                                              handleStatusUpdate
                                            }
                                            handleStatusConfirmUpdate={
                                              handleStatusConfirmUpdate
                                            }
                                            index={index}
                                          />
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <NoData heading='No Redeem Data' />
                                )}
                              </div>
                              {liveGameRedeem?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveGameRedeem?.page}
                                  totalpages={liveGameRedeem?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='canceled' title='Canceled'>
                  <div className='admin-recharge-cancel'>
                    <div className='admin-game-table'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <SearchBoxComponent
                            searchFunction={searchGameReddem}
                          />
                          {!laoding ? (
                            <>
                              <div className='admin-custom-table'>
                                {liveGameRedeem.results &&
                                liveGameRedeem.results.length > 0 ? (
                                  liveGameRedeem.results.map(
                                    (element, index) => {
                                      return (
                                        <>
                                          <RedeemCancelRow
                                            key={element?.id ?? element?._id}
                                            getObjectData={element}
                                            handleStatusUpdate={
                                              handleStatusUpdate
                                            }
                                            handleStatusConfirmUpdate={
                                              handleStatusConfirmUpdate
                                            }
                                            index={index}
                                          />
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <NoData heading='No Redeem Data' />
                                )}
                              </div>
                              {liveGameRedeem?.totalPages > 1 && (
                                <PagePaginationNew
                                  page={liveGameRedeem?.page}
                                  totalpages={liveGameRedeem?.totalPages}
                                  handlePagination={handlePagenation}
                                />
                              )}
                            </>
                          ) : (
                            <TableLoader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default GameRedeem;

const RedeemRequestRow = ({
  getObjectData,
  handleStatusUpdate,
  handleStatusConfirmUpdate,
  index,
}) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={getObjectData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{getObjectData?.gameId?.name || 'Dummy Game'}</h6>
          <p>{getObjectData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={getObjectData?.userId?.avatar||usericon1} alt='game' />
        <div className='user-name-box'>
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className='reacharge-amt-grid'>
        <div className='reacharge-amt'>
          <p>{getObjectData?.transaction}</p>
          <h6>
            <img src={coinicon} alt='coin' /> {getObjectData?.coin}
          </h6>
        </div>
      </div>
      <div className='action-btn'>
        <Button
          className='cancle-btn'
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}>
          Cancel
        </Button>
        <Button
          className='confirm-btn'
          onClick={() => handleStatusConfirmUpdate(getObjectData?.id, index)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

const RedeemConfirmRow = ({ getObjectData, handleStatusUpdate, index }) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={getObjectData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{getObjectData?.gameId?.name || 'Dummy Game'}</h6>
          <p>{getObjectData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={getObjectData?.userId?.avatar||usericon1} alt='game' />

        <div className='user-name-box'>
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className='reacharge-amt-grid'>
        <div className='reacharge-amt'>
          <h6>
            <img src={coinicon} alt='coin' /> {getObjectData?.coin}
          </h6>
        </div>
      </div>
      <div className='request-status-grid'>
        <div className='request-status'>
          <h6 className='confirm'>Confirmed</h6>
          <p>{getDate(getObjectData?.createdAt)}</p>
        </div>
      </div>
      <div className='single-action-btn'>
        <Button
          className='confirm-btn'
          onClick={() => handleStatusUpdate(getObjectData?.id, index)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const RedeemCancelRow = ({
  getObjectData,
  handleStatusConfirmUpdate,
  index,
}) => {
  return (
    <div className='admin-custom-row'>
      <div className='game-name-grid'>
        <img src={getObjectData?.gameId?.logo || gameicon} alt='game' />
        <div className='game-name-box'>
          <h6>{getObjectData?.gameId?.name || 'Dummy Game'}</h6>
          <p>{getObjectData?.gameId?.playCount || 0} Games</p>
        </div>
      </div>
      <div className='user-name-grid'>
      <img src={getObjectData?.userId?.avatar||usericon1} alt='game' />
        <div className='user-name-box'>
          <h6>{getObjectData?.userId?.nickName}</h6>
          <p>{getObjectData?.userId.email}</p>
        </div>
      </div>
      <div className='reacharge-amt-grid'>
        <div className='reacharge-amt'>
          <h6>
            <img src={coinicon} alt='coin' /> {getObjectData?.coin}
          </h6>
        </div>
      </div>
      <div className='request-status-grid'>
        <div className='request-status'>
          <h6 className='cancel'>Canceled</h6>
          <p>{getDate(getObjectData?.createdAt)}</p>
        </div>
      </div>
      <div className='single-action-btn'>
        <Button
          className='confirm-btn'
          onClick={() => handleStatusConfirmUpdate(getObjectData?.id, index)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
